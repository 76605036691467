import ReactGA from 'react-ga';

import config from '../config';
const queryParams = new URLSearchParams(window.location.search);


const analytics = {
    initialize: (user_id, fullname) => {
      queryParams.get('debug') && console.log("Analytics initialize for user: "+fullname);
        //console.log("USER_ID: "+user_id)
        return ReactGA.initialize(config.googleAnalyticsTrackerId, {
          debug: queryParams.get('debug') ? true : false,
          titleCase: false,
          gaOptions: {
            userId: user_id,
            fullname: fullname
          }
        });
    },
    pageview: path => {
      queryParams.get('debug') && console.log("Analytics pageview path: "+path);
        return ReactGA.pageview(path);
    }
}

export default analytics;