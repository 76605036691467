import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import reportWebVitals from './reportWebVitals';
import store from './store/index';

import {checkCookie} from "./context/authContext";
import Orient from './apps/orient';
import Talent from './apps/talent';
import config from './config.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';


Bugsnag.start({
	apiKey: '42ee7bdd54eb29b0f20479ba4f60c0ae',
	plugins: [new BugsnagPluginReact()],
	enabledReleaseStages: [ 'production', 'staging' ]
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const AppSelector = () => {
	const session = checkCookie(config.cookieName);
	// console.log(session?.user?.plan_slug)
	// console.log("loading orientamento? "+session?.user?.plan_slug === "m1-o26")
	return (session?.user?.plan_slug === "m1-o26") ? <Orient /> : <Talent />;
}


ReactDOM.render(
	<ErrorBoundary>
		<Provider store={store}>
			<AppSelector />
		</Provider>
	</ErrorBoundary>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
