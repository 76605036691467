import React from "react";

import config from "../../../config";
import styles from "./style.module.css";



const UpsellingTab = () => {
    return (
        <div className={styles.container}>
            <h5 className={styles.text}>
                Hai scoperto cosa ti piace?
            </h5>
            <a href={`${config.basepath}inizia-master`} className={styles.button}>INIZIA IL TUO MASTER</a>
        </div>
    )
}

export default UpsellingTab;