
import React from 'react';

import './style.css';



const Footer = () => {

	return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-lg-9">
                        <p className="text-light align-middle p-3">
                            start2impact University | B Corp © 2022 | PIVA 10104990964
                            &nbsp;<a href="https://www.start2impact.it/privacy-policy/" target="_blank" rel="noreferrer">Privacy</a>&nbsp;
                            |&nbsp;<a href="https://www.start2impact.it/termini-e-condizioni/" target="_blank" rel="noreferrer">Termini</a>&nbsp;
                            <br />
                            <span className="green">WhatsApp - 3891567926</span>
                            &nbsp;|&nbsp; 
                            <a href="https://www.start2impact.it/blog-page/" target="_blank" rel="noreferrer">
                                <span className="green">Blog</span>
                            </a>
                            &nbsp;|&nbsp; 
                            <a href="https://start2impact.customerly.help/" target="_blank" rel="noreferrer">
                                <span className="green">Help Center</span>
                            </a>
                        </p>
                    </div>
                    <div className="col-xs-12 col-lg-3">
                        <p className="p-3 text-light footer-socials">
                            <a href="https://www.instagram.com/start2impact/?hl=it">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://it-it.facebook.com/start2impact/">
                                <i className="fab fa-facebook"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UC3pEI-6M5tlZe1sSrkERSww">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;