import React from 'react';
import { bool, number, object, string } from 'prop-types';

import { Row, Col, List } from 'reactstrap';
import { FaBook } from 'react-icons/fa'
import { Doughnut } from 'react-chartjs-2';

import styles from "./style.module.css";
import progressColors from '../progressColors';
import { createGradient } from '../../../helpers/colors';

const TheoryProgress = ({
    isMobile = false,
    theory_progress,
    isFinalProject,
    guidePath,
    doughnutSetting
}) => {
    
    const theoryData = {
        maintainAspectRatio: true,
        responsive: true,
        labels: theory_progress===0?['Guida da completare']: (theory_progress===100)?["Guida completata"]:[`Guida completata al ${theory_progress}%`],
        datasets: [
            {
                data: theory_progress>0?[theory_progress, 100-theory_progress]:[100],
                backgroundColor: context => [theory_progress >0 ? createGradient(context) :progressColors.inactiveBackgroundColor, progressColors.inactiveBackgroundColor],
                hoverBorderColor: ['transparent'],
            },
        ],
        isButtonCTA: theory_progress === 100 ? false : true
    };


    return (
        !isMobile ? (
            !isFinalProject && (
                    <Col className="mb-lg-0">
                        <div className={`${styles.homeGuideChart}`}>
                            <Row md={12} className={`${styles.chartRow} no-gutters align-items-center`}>
                                <Col lg={3} md={3}>
                                    <div className='chart-container'>
                                        <Doughnut data={theoryData} options={doughnutSetting} className="chart" width={80} />
                                        <div>
                                            <FaBook className={`${styles.chartIcon} ${theory_progress>0? styles.iconGreen : styles.iconGray }`} />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={9} md={9} className="text-lg-left">
                                    <List type="unstyled">
                                        { theoryData['labels'].map( (label, i) => (<li key={'doughnut_theory_key_'+i}>{label}</li>) )}
                                    </List>
                                </Col>
                            </Row>
                            <a className={`${styles.chartCardButton} ${theoryData.isButtonCTA && styles.buttonActive} `} href={guidePath+'#guide'}>VAI ALLA GUIDA</a>
                        </div>
                    </Col>
                )
            
        ) : (
            !isFinalProject && (
                <div className={`${styles.homeGuideChart}`}>
                    <Row md={12} className={`mb-3 p-2 ${styles.chartRow} no-gutters align-items-center justify-content-center`}>
                        <Col xs={3} className="p-0">
                            <div className={styles.chartContainer}>
                                <Doughnut data={theoryData} options={doughnutSetting} className="chart" width={80} />
                                <div>
                                    <FaBook className={`${styles.chartIcon} ${theory_progress>0? styles.iconGreen : styles.iconGray }`} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={9} className={`text-xs-left p-0 ${styles.collapseLabel}`}>
                            <List type="unstyled">
                                { theoryData['labels'].map( (label, i) => (<li key={'doughnut_theory_key_'+i}>{label}</li>) )}
                            </List>
                        </Col>
                    </Row>
                    <a className={`btn ${styles.chartCardButton} ${theoryData.isButtonCTA && styles.buttonActive}`} href={guidePath+'#guide'}>VAI ALLA GUIDA</a>
                </div>
            )
        )
    )
}


TheoryProgress.propTypes = {
	isMobile: bool.isRequired,
    theory_progress: number.isRequired,
    isFinalProject: bool.isRequired,
    guidePath: string.isRequired,
    doughnutSetting: object.isRequired
};

export default TheoryProgress