import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchBadgesFailed, fetchBadgesSuccess, fetchBadgesRequest, setBadgeHasBeenSeenFail, setBadgeHasBeenSeenRequest, setBadgeHasBeenSeenSuccess } from '../slices/badge';

import { badgeApi } from '../api/badge';

function* fetchBadgeSaga({payload: {user_id}}) {
	try {
        const response = yield call(badgeApi.list, user_id);
		yield put(fetchBadgesSuccess(response.data.arr));
	} catch (error) {
		yield put(fetchBadgesFailed(error));
	}
}

function* setBadgeHasBeenSeenRequestSaga({ payload:{user_id, badge_id} }) {
	try {
		const response = yield call(badgeApi.setHasBeenSeen, user_id, badge_id);
		yield put(setBadgeHasBeenSeenSuccess());

	} catch (error) {
		yield put(setBadgeHasBeenSeenFail(error));
	}
}

export default function* badgeSaga() {
	yield takeEvery(fetchBadgesRequest.toString(), fetchBadgeSaga);
	yield takeEvery(setBadgeHasBeenSeenRequest.toString(), setBadgeHasBeenSeenRequestSaga);
}
