import { put, takeEvery, call } from 'redux-saga/effects';

import { 
	notificationsFetchRequest, notificationFetchSuccess, notificationFetchFail,
	setAllNotificationsAsReadRequest, setAllNotificationsAsReadSuccess, setAllNotificationsAsReadFail,
	setNotificationAsReadRequest
 } from '../slices/notifications';

import { notificationsApi } from '../api/notifications';
//import { notifications } from '../../store/fakedb';



function* fetchNotificationsSaga({ payload:{user_id} }) {
	try {
		//console.log('fetchNotificationsSaga user_id', user_id);
		const response = yield call(notificationsApi.list, user_id);
		yield put(notificationFetchSuccess(response.data.notifications));
	}
	catch (error) {
		yield put(notificationFetchFail('There was an error trying to get the request.'));
	}
}

function* setAllNotificationsAsReadRequestSaga({ payload:{user_id} }) {
	try {
		const response = yield call(notificationsApi.setAllAsRead, user_id);
		//console.log('setAllNotificationsAsReadRequestSaga response', response.data.message);
		yield put(setAllNotificationsAsReadSuccess(response));
	}
	catch (error) {
		yield put(setAllNotificationsAsReadFail(error));
	}
}

function* setNotificationAsReadRequestSaga({ payload:{user_id, notification, notifications} }) {
	try {
		const request = yield call(notificationsApi.setOneAsRead, user_id, notification.id);
		const response = notifications.map(element => element.id === notification.id ? {...element, read_at: new Date()} : element);
		yield put(notificationFetchSuccess(response));
	}
	catch (error) {
		yield put(notificationFetchFail('There was an error trying to get the request.'));
	}
}


export default function* notificationsSaga() {
	yield takeEvery(notificationsFetchRequest, fetchNotificationsSaga);
	yield takeEvery(setAllNotificationsAsReadRequest, setAllNotificationsAsReadRequestSaga);
	yield takeEvery(setNotificationAsReadRequest, setNotificationAsReadRequestSaga);
}