import React from 'react';

import { Row, Col } from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';

import TheoryProgress from './TheoryProgress.js';
import TestProgress from './TestProgress.js';

import ProjectProgress from './ProjectProgress.js';
import { calculateDaysToToday } from '../../../helpers/dates.js';

const doughnutSetting = {
    cutout: '70%',
    plugins: {
        legend: { display: false, position: 'right' },
        tooltip: { enabled: false },
    },
    elements: { arc:{borderWidth:1} },
    rotation: 360,
    circumference: 360/1
};

const RenderContent = ({
    isMobile,
    children,
    keyId,
    openProjectCarouselItem = false,
    theory_progress,
    isFinalProject,
}) => {


    return (
        <Row key={keyId}>
            {isMobile ? (
                <Col xs={12}>
                    <Carousel 
                    showThumbs={false} 
                    showStatus={false} 
                    selectedItem={isFinalProject ? 2 : theory_progress !== 100 ? 0 : openProjectCarouselItem ? 2 : 1}>
                        {children}
                    </Carousel>
                </Col>
            ): (
                <>
                    {children}
                    {
                        isFinalProject && (
                            <>
                                <Col className="mb-lg-0"></Col>
                                <Col className="mb-lg-0"></Col>
                            </>
                        )
                    }   
                </>
            )}
        </Row>
    )
}


const GuideProgressDetails = ({ 
    keyId, 
    guidePath,
    theory_progress = 0, 
    test_points = 0, 
    test_max_points = 0, 
    test_retries = 0, 
    test_blocked_until = null,
    project_blocked_until = null, 
    project_retries = 0, 
    project_resend = false, 
    project_sentnotcorrected = null,
    projects_count = 0, 
    projects_completed = 0, 
    projects_points = "",
    isFinalProject,
}) => {
    const isMobile = window.innerWidth < 768;

    // check if project is available and test is completed
    const openProjectCarouselItem = () => {
        return (
            !(project_sentnotcorrected !== null 
            || project_blocked_until !== null && calculateDaysToToday(project_blocked_until) > 0
            || projects_completed<projects_count && test_points === 0) 
            )
    }

    return (
            <RenderContent
                isMobile={isMobile}
                keyId={keyId}
                openProjectCarouselItem={openProjectCarouselItem()}
                theory_progress={theory_progress}
                isFinalProject={isFinalProject}
            >
                <TheoryProgress 
                    isMobile={isMobile} 
                    theory_progress={theory_progress} 
                    isFinalProject={isFinalProject} 
                    guidePath={guidePath}
                    doughnutSetting={doughnutSetting}
                />

                <TestProgress 
                    isMobile={isMobile}
                    test_max_points={test_max_points}
                    test_points={test_points}
                    test_retries={test_retries}
                    theory_progress={theory_progress}
                    guidePath={guidePath}
                    isFinalProject={isFinalProject}
                    test_blocked_until={test_blocked_until}
                    doughnutSetting={doughnutSetting}
                />

                <ProjectProgress 
                    isMobile={isMobile}
                    guidePath={guidePath}
                    isFinalProject={isFinalProject}
                    project_blocked_until={project_blocked_until}
                    project_resend={project_resend}
                    project_sentnotcorrected={project_sentnotcorrected}
                    projects_completed={projects_completed}
                    projects_count={projects_count}
                    projects_points={projects_points}
                    project_retries={project_retries}
                    test_points={test_points}
                    doughnutSetting={doughnutSetting}
                />           
            </RenderContent>
    );
};

export default GuideProgressDetails;