import axios from "axios"



const http = axios.create({
    //baseURL: 'http://localhost:3004',
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        //'Referrer-Policy': 'no-referrer'
    },
    crossDomain:true,
    //withCredentials: true,
    //credentials: "include",
})

//http.defaults.headers.common["Authorization"] = authHeader()
// axiosInstance.interceptors.request.use((config) => {
//     config.headers = { authorization: 'Bearer ' + sessionStorage.getItem('token') };
//     return config;
// });


export default http;