import { createPortal } from 'react-dom';
import React, { useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { checkCookie } from "../../context/authContext";
import { fetchBadgesRequest, setBadgeHasBeenSeenRequest } from "../../store/slices";

import PopupBadge from "../../components/PopupBadge";

import config from "../../config";
import { useLocation } from 'react-router-dom';

const PortalBadge = () => {
  const dispatch = useDispatch();
  const session = checkCookie(config.cookieName);
  const location = useLocation();

  const [isOpenBadgeModal, setIsOpenBadgeModal] = useState(false);
  const [showedBadge, setShowedBadge] = useState(null);

  const {data: badges} = useSelector(state => state.badgesSlice); 

  const onClosePopupBadge = (selectedBadge = null) => {
    setIsOpenBadgeModal(false);
    setShowedBadge(null);
    dispatch(setBadgeHasBeenSeenRequest({user_id: session.user.id, badge_id: selectedBadge.id}));
  }

  useEffect(() => {
    if(session.user.id){
      dispatch(fetchBadgesRequest({user_id: session.user.id}))
    }
  }, [location.pathname]);

  useEffect(() => {
    if(badges.length > 0){
      setShowedBadge(badges[badges.length - 1]);
      setIsOpenBadgeModal(true);
    }
  },[badges])
  

  return (
    isOpenBadgeModal && createPortal(
        <PopupBadge userId={session.user.id} badge={showedBadge} isOpen={isOpenBadgeModal} togglePopup={() => onClosePopupBadge(showedBadge)}/>,
        document.getElementById("badges")
      )
  )
}

export default PortalBadge;