import React from 'react';
import { bool, number, object, string } from 'prop-types';

import { Button, Row, Col, List } from 'reactstrap';
import { FaFileInvoice } from 'react-icons/fa'
import { Doughnut } from 'react-chartjs-2';

import styles from "./style.module.css"
import progressColors from "../progressColors";
import { calculateDaysToToday } from '../../../helpers/dates.js';

const ProjectProgress = ({
    isMobile,
    isFinalProject,
    test_points,
    project_retries,
    project_resend,
    project_sentnotcorrected,
    project_blocked_until,
    projects_count,
    projects_completed,
    projects_points,
    guidePath,
    doughnutSetting
}) => {

    const checkProjectBlockedUntil = () => {
        return project_blocked_until!==null && calculateDaysToToday(project_blocked_until)>0
    }


    const getProjectStatusColor = (context) => {
        let projectsColor = [];

        [...Array(projects_count)].map((project, index) => {
            if(projects_completed >= index + 1){

                projectsColor.push(progressColors.activeBackgroundColor(context));

            } else {

                // if the index is greater than zero, and completed projects are less than the current index
                if(index > 0 && projects_completed < index ) { 
                    projectsColor.push(progressColors.inactiveBackgroundColor);
                } 
                
                // if project is sended but still not corrected
                else if(project_sentnotcorrected !== null){
                    projectsColor.push(progressColors.waitingBackgroundColor);
                }     

                // if project must be resended 
                else if(project_resend === true){
                    projectsColor.push(progressColors.resendBackgroundColor);
                } 
                
                // if project is blocked
                else if(project_blocked_until !== null || project_retries > 0){
                    projectsColor.push(progressColors.blockedBackgroundColor);
                }
                
                else {
                    projectsColor.push(progressColors.inactiveBackgroundColor);
                }
            }
        })

        return projectsColor;
    }


    const projectDetails = (
        test_points, 
        project_blocked_until, project_retries, project_resend, project_sentnotcorrected,
        projects_count, projects_completed, projects_points
    ) => {

        let textCopy = null;
        let isButtonActive = false;

        if (project_sentnotcorrected!==null) {                                          // Progetto in attesa di correzione
            textCopy = `Progetto inviato, riceverai un feedback entro 7 giorni`;
            isButtonActive = false;
        }

        else if (project_resend===true) {                                               // Progetto da reinviare
            textCopy = 'Progetto da reinviare';
            isButtonActive = true;
        }
        else if (project_blocked_until!==null && calculateDaysToToday(project_blocked_until)>0) {       // 3 volte bocciato, deve aspettare per riprovare
            textCopy = `Progetto non superato<br />Potrai riprovare tra ${calculateDaysToToday(project_blocked_until)} giorni`;
            isButtonActive = true;
        }
        else if (checkProjectBlockedUntil()) {       // 3 volte bocciato ma può riprovare
            textCopy = `Progetto non superato<br />Riprova!`;
            isButtonActive = true;
        }
        else if (project_retries===1) {                    // Progetto con bocciatura, 1 tentativi rimanenti
            textCopy = `Progetto non superato con 1 tentativo rimanente`;
            isButtonActive = true;
        }
        else if (projects_count>0 && (projects_completed===projects_count)) {          // tutti i progetti sono stati svolti
            let projectPoints = null;
            if (projects_completed===1) {
                projectPoints = projects_points.indexOf(':')>-1 ? projects_points.split(':') : [];
                textCopy = projectPoints!==null ? 'Progetto '+ projectPoints[0]+'/'+projectPoints[1] : 'Errore'
            }
            else {
                let projects= projects_points.indexOf(':')>-1 ? projects_points.split(',') : [];
                textCopy=projects.map((points,i) => `Progetto ${i+1} - `+ points.split(':')[0]+'/'+points.split(':')[1]+'<br />').join('');
            }
            isButtonActive = true;
        }
        else if(projects_count===1 && projects_completed===0 && (test_points>0 || isFinalProject) ) {       // Progetto non svolto
            textCopy = `Progetto da completare`;
            isButtonActive = true;
        }
        else if (projects_completed<projects_count && test_points===0) {                 // test non completato
            textCopy = `Potrai fare il progetto una volta completato il test`;
            isButtonActive = false;
        }
        else if (projects_completed<projects_count) {                                    // progetto non completato
            textCopy = projects_count-projects_completed===1? '1 Progetto rimanente' : `${projects_count-projects_completed} Progetti rimanenti`;
            isButtonActive = true;
        }
        else {
            textCopy = `Questo non era previsto! Ce lo segnali? :)`;                     // bug
            isButtonActive = false;
        }
        return {
            data: projects_count===1 ? [100] : [...Array(projects_count)].map( (x,i) => 100/projects_count ),
            backgroundColor: context => getProjectStatusColor(context, projects_completed, project_sentnotcorrected, project_resend, project_blocked_until, project_retries, projects_count),
            icon: [...Array(projects_count)].map( (x,i) => projects_completed>=i+1? styles.iconGreen : (project_sentnotcorrected!==null)? styles.iconYellow : (project_resend===true)? styles.iconOrange : (project_blocked_until !== null || project_retries > 0)? styles.iconRed : styles.iconGray ),
            text: textCopy,
            button: isButtonActive,
        }
    };

    


    const projectsData = {
        maintainAspectRatio: true,
        responsive: true,
        labels: [[projectDetails(test_points, project_blocked_until, project_retries, project_resend, project_sentnotcorrected, projects_count, projects_completed, projects_points).text]],
        datasets: [
            {
                data: projectDetails(test_points, project_blocked_until, project_retries, project_resend, project_sentnotcorrected, projects_count, projects_completed, projects_points).data,
                backgroundColor: context => projectDetails(test_points, project_blocked_until, project_retries, project_resend, project_sentnotcorrected, projects_count, projects_completed, projects_points).backgroundColor(context),
                hoverBorderColor: ['transparent'],
            }
        ],
        icon: projectDetails(test_points, project_blocked_until, project_retries, project_resend, project_sentnotcorrected, projects_count, projects_completed, projects_points).icon,
        isButtonActive: projectDetails(test_points, project_blocked_until, project_retries, project_resend, project_sentnotcorrected, projects_count, projects_completed, projects_points).button
    };


    return (
        !isMobile ? (
            projects_count > 0 && (
                <Col className="mb-lg-0">
                    <div className={`${styles.homeGuideChart}`}>
                        <Row className={`${styles.chartRow} no-gutters align-items-center`}>
                            <Col lg={3} sm={2} md={4}>
                                <div className="chart-container">
                                    <Doughnut data={projectsData} options={doughnutSetting} className="chart" width={80} />
                                    <div>
                                        <FaFileInvoice className={`${styles.chartIcon} ${projectsData.icon[projectsData.icon.length-1]}`} /> 
                                    </div>
                                </div>
                            </Col>
                            <Col lg={9} sm={10} md={8} className="text-lg-left">
                                <List type="unstyled">
                                    {
                                        projectsData['labels'].map( (label, i) => (
                                            <li key={'doughnut_project_key_'+i}>
                                                <span dangerouslySetInnerHTML={{__html:label}}></span>
                                            </li>
                                        ))
                                    }
                                </List>
                            </Col>
                        </Row>
                        {
                            projects_completed === projects_count
                            ? <a className={`${styles.chartCardButton} ${styles.buttonActive}`} href={guidePath+'#project_to_show'}>{ (projects_completed===projects_count) ? 'VISUALIZZA FEEDBACK' : projects_count > 1 ? 'VAI AI PROGETTI' : 'VAI AL PROGETTO'}</a>
                            : <a className={`${styles.chartCardButton} ${projectsData.isButtonActive ? styles.buttonActive : styles.buttonInactive} ${checkProjectBlockedUntil() && styles.buttonActiveSecondary}`} href={guidePath+'#project_to_show'}>VAI {projects_count > 1 ? 'AI PROGETTI' : 'AL PROGETTO'}</a>
                        }
                    </div>
                </Col>
            )
        ) : (

            projects_count && (
                <div className={`${styles.homeGuideChart}`}>
                    <Row className={`mb-3 p-2 ${styles.chartRow} no-gutters align-items-center`}>
                        <Col xs={3} className="p-0">
                            <div className={styles.chartContainer}>
                                <Doughnut data={projectsData} options={doughnutSetting} className="chart" width={80} />
                                <div>
                                    <FaFileInvoice className={`${styles.chartIcon} ${projectsData.icon[projectsData.icon.length-1]}`} /> 
                                </div>
                            </div>
                        </Col>
                        <Col xs={9} className={`text-lg-left p-0 ${styles.collapseLabel}`}>
                            <List type="unstyled">
                                {
                                    projectsData['labels'].map( (label, i) => (
                                        <li key={'doughnut_project_key_'+i}>
                                            <span dangerouslySetInnerHTML={{__html:label}}></span>
                                        </li>
                                    ))
                                }
                            </List>
                        </Col>
                    </Row>

                    {   
                        projects_completed === projects_count
                        ? <a className={`btn ${styles.chartCardButton}`} href={guidePath+'#project_to_show'}>{ (projects_completed===projects_count) ? 'Visualizza Feedback' : projects_count>1 ? 'Vai ai Progetti' : 'Vai al Progetto'}</a>
                        : <Button className={`btn ${styles.chartCardButton} ${projectsData.isButtonActive && styles.buttonActive} ${checkProjectBlockedUntil() && styles.buttonActiveSecondary}`} href={guidePath+'#project_to_show'} disabled={!projectsData.isButtonActive}>VAI {projects_count > 1 ? 'AI PROGETTI' : 'AL PROGETTO'}</Button>
                    }
                </div>
            )
        )
    )
}

ProjectProgress.propTypes = {
	isMobile: bool.isRequired,
    guidePath: string.isRequired,
    isFinalProject: bool.isRequired,
    project_resend: bool.isRequired,
    project_retries: number.isRequired,
    projects_completed: number.isRequired,
    projects_count: number.isRequired,
    projects_points: string.isRequired,
    test_points: number.isRequired,
    doughnutSetting: object.isRequired,
};


export default ProjectProgress;