import React from 'react';
// import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';



const Loader = ({ color }) => {
	return (
		<div style={{
			display: "flex",
			height: "100vh",
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			{/* <Spinner color={color} /> */}
			<img
			 	src="https://res.cloudinary.com/start2impact/image/upload/v1674210016/design/Spinner_wgj65s.gif"
				style={{
					borderRadius: '100px',
					boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.05)'
				}}
				height="100"
				width="100"
				alt="Spinner"
			/>
		</div>
	);
};

Loader.propTypes = {
	color: PropTypes.string
}
export default Loader;
