import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
    error: null,
    status: "idle" // "idle", "loading", "completed"
};


const coursesSlice = createSlice({
	name: 'courses',
	initialState,
	reducers: {
        coursesFetchRequest(state) {
			state.status = 'loading';
		},
		coursesFetchSuccess(state, action) {
			state.data = action.payload;
			state.status = 'completed';
		},
		coursesFetchFail(state, action) {
			state.error = action.payload;
			state.status = 'idle';
		},
	},
});


export const {
    coursesFetchRequest, coursesFetchSuccess, coursesFetchFail
} = coursesSlice.actions;

export default coursesSlice.reducer;
