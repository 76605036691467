import {bool, oneOf, string} from 'prop-types';

const Logo = ({
    basepath,
    isCollapsed=false,
    className,
    type = "black"
}) => {

    return (
        <div className={`pt-3 text-center ${className}`}>
            <a href='/'>
                {
                    isCollapsed ? 
                    <img src={`${basepath}images/logos/mini.svg`} width="41" height="41" alt="start2impact logo" />
                    : 
                    type === "black" ? 
                    <img src={`${basepath}images/logos/full.svg`} width="191" height="40" alt="start2impact logo" />
                    :
                    <img src={`${basepath}images/logos/fullWhite.svg`} width="191" height="40" alt="start2impact logo" />
                }
            </a>
        </div>
    );

}

Logo.propTypes = {
	isCollapsed: bool,
    basepath: string,
    type: oneOf(["white", "black"])
}

export default Logo;

