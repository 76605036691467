import React from 'react';
import { BrowserRouter, Switch } from "react-router-dom";

import { AuthProvider } from "../context/authContext";

import LayoutMenu from '../layouts/LayoutMenu.js';
import LayoutWithoutMenu from '../layouts/LayoutWithoutMenu.js';

import ModalWelcomeVideo from '../components/ModalWelcomeVideo';
import PortalBadge from "../portals/badges/PortalBadge";

import StudentHome from '../features/StudentHome';
import Error404 from '../features/404';
import Upselling from '../features/Upselling';


import "react-responsive-carousel/lib/styles/carousel.min.css";
import './style.css';

import config from '../config.js';


export default function Orient() {
	// console.log("loaded orientamento");
	return (
		<BrowserRouter>
			<AuthProvider>
				<ModalWelcomeVideo />
				<PortalBadge />
				<Switch>
					<LayoutMenu exact path={`${config.basepath}home`} component={StudentHome} />
					<LayoutWithoutMenu path={`${config.basepath}inizia-master`} component={Upselling} />
					<LayoutWithoutMenu path='*' component={Error404} />
				</Switch>
			</AuthProvider>
		</BrowserRouter>
	);

}