import React from 'react';
import { Route } from 'react-router-dom';

import useAuth from "../context/authContext";
import config from '../config.js';

import SideBar from '../components/SidebarMenu';
// import Footer from '../components/Footer';


export default function LayoutMenu({component: Component, ...rest}) { 

  const { session, user } = useAuth();

  // su /profile/* non effettuare redirect
  // if (false===session && location.pathname.split('/')[1]==='profile') return null;
  // if(typeof session==='undefined' || !session || typeof user ==='undefined')
  //   return window.location = config.loginPath;
  // else
    return (
      <Route {...rest} render={ otherProps => (
        <>
          <SideBar menuItems={config.menuItems(session?.user?.plan_slug)} loginPath={config.loginPath} userSession={session?.user} userRecord={user} />
          <Component userSession={session?.user} {...otherProps} />
        </>
      )} />
    );

}