import { Helmet } from "react-helmet";
import { Col, Container, Row } from "reactstrap";

import { isBrowser } from "react-device-detect";

import Footer from "../../components/Footer";
import MobileToggleIcon from "../../components/SidebarMenu/MobileToggleIcon";

import "./style.css";

import config from "../../config";

const Community = userSession => {
    return (
        <>
            <Helmet>
                <title>{config.menuItems(userSession.plan_slug)[4].title}</title>
            </Helmet>

            <div className="community-page">
                <main className="d-flex align-items-center py-4 mh-100">
                    <Container>
                        {!isBrowser && (
                            <nav>
                                <MobileToggleIcon />
                            </nav>
                        )}
                        <Row>
                            <Col xs={12} className="mb-5 pt-2">
                                <div className="col-lg-8 mx-auto">
                                    <img
                                        src="https://res.cloudinary.com/start2impact/image/fetch/c_fill,w_860/https://talent.start2impact.it/assets/slides/s2i_community-ff0036c677edff8171f1ced179fe74719b91b0c6ee83e231de17011180b17620.jpg"
                                        alt="Foto della community start2impact"
                                        loading="eager"
                                        width="860"
                                        height="574"
                                    />
                                </div>
                            </Col>

                            <Col xs={12} className="text-center">
                                <h1 className="community-title">
                                    Entra a far parte della Community
                                </h1>
                                <p className="community-subtitle mb-4">
                                    Potrai confrontarti sia con esperti, sia con studenti con i tuoi
                                    stessi interessi.
                                </p>
                                <a
                                    href="https://www.facebook.com/groups/start2impact.eccellenza"
                                    target="_blank"
                                    className="community-link"
                                    rel="noreferrer"
                                >
                                    Entra nel gruppo
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </main>

                <Footer />
            </div>
        </>
    );
};

export default Community;
