import React from 'react';
import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import CardGuide from '../CardGuide';
import '../../components/CardGuide/style.css';

import './style.css';



const ModalMasterDetails = ({toggle, master:{id, title, guides_count, projects_count, theories_hours, projects_hours, responsible, topic, containers, student_infos}, startNewMaster=null}) => {

	return (
		<div className='path-details'>
			<Modal isOpen={true} toggle={toggle} className='scope-change-modal' size='lg'>
				<ModalHeader toggle={toggle}>
					Programma del Master {title}
				</ModalHeader>
				<ModalBody>
						<Row className='details-card'>
							<Col id='theories_count' xs={12} sm={6} lg={3} className='my-2 guide-item'>
								<div className='stat-block d-inline-flex align-items-center w-100 px-3 rounded-3'>
									<span className='value flex-shrink-0 me-2'>{guides_count}</span>
									<span className='flex-shrink-0'>Guide Teoriche</span>
								</div>
							</Col>
							<Col id='projects_count' xs={12} sm={6} lg={3} className='my-2 guide-item'>
								<div className='stat-block d-inline-flex align-items-center w-100 px-3 rounded-3'>
									<span className='value flex-shrink-0 me-2'>{projects_count}</span>
									<span className='flex-shrink-0'>Progetti Pratici</span>
								</div>
							</Col>
							<Col id='theories_hours' xs={12} sm={6} lg={3} className='my-2 guide-item'>
								<div className='stat-block d-inline-flex align-items-center w-100 px-3 rounded-3'>
									<span className='value flex-shrink-0 me-2'>{theories_hours}</span>
									<span className='flex-shrink-0'>Ore di Teoria</span>
								</div>
							</Col>
							<Col id='projects_hours' xs={12} sm={6} lg={3} className='my-2 guide-item'>
								<div className='stat-block d-inline-flex align-items-center w-100 px-3 rounded-3'>
									<span className='value flex-shrink-0 me-2'>{projects_hours}</span>
									<span className='flex-shrink-0'>Ore di Pratica</span>
								</div>
							</Col>
						</Row>
						<Row className='mt-3 details-card'>
							<Col xs={12}>
								<Card body className='profile-card rounded-3 border-0'>
									<Row className='align-items-center ps-2'>
										<Col xs={12} md={4} style={{backgroundColor: topic.color}} className='rounded-3'>
											<img
												className='card-img path-responsible-img'
												src={'https://res.cloudinary.com/start2impact/image/fetch/'+responsible.pic_url}
												alt={'Immagine profilo di '+responsible.user.firstname}
											/>
										</Col>
										<Col xs={12} md={8}>
											<h4 className='name mb-1 mt-4 mt-md-0'>{responsible.user.firstname} {responsible.user.lastname}</h4>
											<h5 className='sub-title mb-3'>Responsabile {topic.name}</h5>
											<p>{responsible.user.bio}</p>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					<h4 className='super-guide-header'>Corsi</h4>
					<Row className="scrollable">
						{
							containers.map(container => {
								const details = container.details[0];
								const type = container.type; // final project or...
								const pic_url = details?.guide.image ? details?.guide.image.url : 'https://via.placeholder.com/150';
								return (
									<Col key={container.id} xs={12} className='py-2'>
										<Card className='guide-card shadow'>
											<CardBody>
												<CardGuide
													isModalPathDetails={true}
													guideTypeId={type.id}
													pic_url={pic_url}
													master_topic={topic}
													guide={details?.guide}
												/>
											</CardBody>
										</Card>
									</Col>
								)
							})
						}
					</Row>
				</ModalBody>
				<ModalFooter>
					{
						(student_infos===null || student_infos?.progress < 100) && (
								<button key='2' className='btn d-inline-block btn-custom-dark mb-3 mx-auto' onClick={()=>startNewMaster(id)}>
									{student_infos?.is_current ? 'Riprendi' : 'INIZIA MASTER'}
								</button>
							)
					}
				</ModalFooter>
			</Modal>
		</div>
	);
};

ModalMasterDetails.propTypes = {
	toggle:PropTypes.func.isRequired, 
	master: PropTypes.shape({
		id:PropTypes.string,
		title: PropTypes.string,
		guides_count: PropTypes.number,
		projects_count: PropTypes.number, 
		theories_hours: PropTypes.number, 
		projects_hours: PropTypes.number, 
		responsible: PropTypes.object,
		containers: PropTypes.arrayOf(PropTypes.object).isRequired,
	}).isRequired,
	startNewMaster: PropTypes.func.isRequired,
}

export default ModalMasterDetails;
