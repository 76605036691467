import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	lastAccess: null,
    isSubscriptionOk: null,
	error: null,
    status: 'idle'
};


const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {

		hadAccessRequest(state, action) {
			state.status = 'loading';
		},
		hadAccessSuccess(state, action) {
			state.lastAccess = action.payload;
			state.status = 'completed';
		},
		hadAccessFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		},

        isSubscriptionOkRequest(state, action) {
            state.status = 'loading';
        },
		isSubscriptionOkSuccess(state, action) {
			state.isSubscriptionOk = action.payload.data;
			state.status = 'completed';
		},
		isSubscriptionOkFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		}
	},
});


export const {
    hadAccessRequest, hadAccessSuccess, hadAccessFail,
    isSubscriptionOkRequest, isSubscriptionOkSuccess, isSubscriptionOkFail
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
