import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

const Skills = ({ values }) => {
	//console.log(values)
	return (
		<Col xs={12} md={6} className='pl-0 mb-2 mb-md-0 skills'>
			<h6>Skill Principali</h6>
			<div className='tag-container gray'>
				{
					values.length > 0 && values.map( skill => (
						<span key={'skill_'+skill.id} className='tag-skill mt-1'>
							{skill.name}
						</span>
					))
				}
			</div>
		</Col>
	);
};
Skills.propTypes = {
	values: PropTypes.arrayOf(PropTypes.object)
};
export default Skills;
