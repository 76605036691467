import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';



const CoachesList = ({ coaches=[] }) => {
	return (
		<>
			{ /* desktop version */ }
			<div className='d-none d-md-block'>
				<div className='label-coaches text-end pe-2 d-flex flex-row justify-content-end align-items-center'>
					<div className='pe-1'>
						{ coaches.length > 1 ? 'Correggeranno i tuoi Progetti:' : 'Correggerà i tuoi Progetti:'}
					</div>
					{
						coaches.map( coach => (
							coach.pic_profile?.url &&
								<div key={'div_coach_'+coach.id}>
									<img
										key={coach.id}
										id={'coach_'+coach.id}
										className='rounded-circle mx-1'
										src={`https://res.cloudinary.com/start2impact/image/fetch/c_fill,g_face,h_60,w_60/r_max/f_auto/${coach.pic_profile?.url}`}
										alt={`${coach.firstname} ${coach.lastname}`}
										width={42}
									/>
									<UncontrolledTooltip placement="right" target={'coach_'+coach.id}>
										{`${coach.firstname} ${coach.lastname}`}
									</UncontrolledTooltip>
								</div>
						) )
					}
				</div>
			</div>

			{ /* mobile version */ }
			<div className='d-md-none row'>
				<div className='col-12 label-coaches mt-2 mb-1'>
					{ coaches.length > 1 ? 'Correggeranno i tuoi Progetti:' : 'Correggerà i tuoi Progetti:'}
				</div>
				<div className='col-12 d-flex'>
					{
						coaches.map( coach => (
							coach.pic_profile?.url &&
								<div key={'div_coach_'+coach.id} className='align-items-start'>
									<img
										key={coach.id}
										id={'coach_'+coach.id}
										className='rounded-circle mx-1'
										src={`https://res.cloudinary.com/start2impact/image/fetch/c_fill,g_face,h_60,w_60/r_max/f_auto/${coach.pic_profile?.url}`}
										alt={`${coach.firstname} ${coach.lastname}`}
										width={42}
									/>
									<UncontrolledTooltip placement="right" target={'coach_'+coach.id}>
										{`${coach.firstname} ${coach.lastname}`}
									</UncontrolledTooltip>
								</div>
						) )
					}
				</div>
			</div>

		</>
	);
};



CoachesList.propTypes = {
	coaches: PropTypes.arrayOf(PropTypes.object)
};

export default CoachesList;
