import { CardHeader, CardTitle, Card, Row, Col } from "reactstrap";
import { FaBookmark, FaRandom, FaGraduationCap } from "react-icons/fa";

const iconType = (type) => {
  switch (type) {
    case "Corso Opzionale":
      return <FaBookmark className="icon me-1" />;
    case "Scegli un Corso tra":
      return <FaRandom className="icon me-1" />;
    case "Progetto Finale":
      return <FaGraduationCap className="icon me-1" />;
    default:
      return null;
  }
};

const ContainerWithLabel = ({ type, id, displayLabel = false, children }) => {
  return (
    <Card key={"home-guide-" + id} className="card-block custom-shadow mb-3">
      <CardHeader
        className={`${displayLabel ? "d-block pb-0 pt-16" : "d-none"}`}
      >
        <Row className="align-items-center">
          <Col xs={9} sm={8} className="p-0">
            <CardTitle tag="h5" className="mb-2">
              {iconType(type)} {type}
            </CardTitle>
          </Col>
          {/* type === "Opzionale" && (
            <Col xs={3} sm={4} className="text-right">
              <Button color="link" className="delete-btn">
                <FaRegTrashAlt className="icon ml-2" />
              </Button>
            </Col>
          ) */}
        </Row>
      </CardHeader>
      {children}
    </Card>
  );
};

export default ContainerWithLabel;
