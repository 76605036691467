import React from 'react';
import PropTypes from 'prop-types';
import { FaFileInvoice, FaRegClock, FaMedal } from 'react-icons/fa';
import { BsListCheck } from 'react-icons/bs';


const CardGuideStats = ({ studentCompletedGuide=false,
    duration_guide=0, duration_project=0, max_points_guide=0,
    test_points=0, test_max_points=0, projects_count=0, projects_completed=0, projects_points=""
}) => {

    if(!studentCompletedGuide || projects_completed<projects_count) {
        return (
            <ul className='list-group list-group-horizontal-md stats mt-sm-1 mt-3'>
                {
                    duration_guide > 0 && (
                        <li className='ms-0 me-3'>
                            <FaRegClock className='icon me-1' /> {duration_guide} Ore di Teoria
                        </li>
                    )
                }
                {
                    duration_project > 0 && (
                        <li className='ms-0 me-3 mt-1 mt-md-0'>
                            <FaRegClock className='icon me-1' /> {duration_project} Ore di Pratica
                        </li>
                    )
                }
                <li className='ms-0 mt-1 mt-md-0'>
                    <FaMedal className='icon me-1' /> {max_points_guide} pt Max
                </li>
            </ul>
        )
    }
    else {
        // console.log('else: '+projects_points)
        // console.log('projects_count: '+projects_count)
        const projectsLabel = projects_count>1 ? 'Progetti' : 'Progetto';
        const projectsPoints = projects_count>1
            ?
                projects_points.split(',').map(project => project).reduce((a, b) => {
                    // console.log('a[0] '+ a.split(':')[0]);
                    // console.log('b[0] '+ b.split(':')[0]);
                    return +a.split(':')[0] + +b.split(':')[0]}) +'/'+ projects_points.split(',').map(project => project).reduce((a, b) => {
                        // console.log('a[1] '+ a.split(':')[1]);
                        // console.log('b[1] '+ b.split(':')[1]);
                        return +a.split(':')[1] + +b.split(':')[1]})
            :
                `${projects_points.split(':')[0]}/${projects_points.split(':')[1]}`;
        
        return (
            <ul className='list-group list-group-horizontal-md stats mt-sm-1 mt-3'>
                {
                    test_points > 0 && (
                        <li className='ms-0'>
                            <BsListCheck className='icon me-1' /> Test: {test_points}/{test_max_points}
                        </li>
                    )
                }
                {
                    projects_points !== null && (
                        <li className='ms-md-3 mt-1 mt-md-0'>
                            <FaFileInvoice className='icon me-1' /> {projectsLabel}: {projectsPoints}
                        </li>
                    )
                }
            </ul>
        );
    }
};


CardGuideStats.propTypes = {
	studentCompletedGuide: PropTypes.bool,
    duration_guide: PropTypes.number,
    duration_project: PropTypes.number,
    max_points_guide: PropTypes.number,
    test_points: PropTypes.number,
    test_max_points: PropTypes.number,
    projects_count: PropTypes.number,
    projects_result: PropTypes.number
};

export default CardGuideStats;