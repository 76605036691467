import React from "react";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import CardGuide from "../../../components/CardGuide";

import ContainerWithLabel from "./ContainerWithLabel";

import "./style.css";

const GuideCardList = ({
  containers = [],
  topic,
  openFirstGuideCard = false,
}) => {
  console.log(containers);

  return (
    <Row className="home-guide-list mb-3">
      {containers.map((container, i) => {
        const doesContaiLabel =
          container.type.id !== "7f2f2cd0-7121-4038-89e8-6b15546c6cd3"; // a scelta tra, progetto finale, opzionale

        const isFinalProject =
          container.type.id === "1140639d-bbc7-47c7-9477-a2218daf02f2";

        return (
          <ContainerWithLabel
            displayLabel={doesContaiLabel || isFinalProject}
            type={isFinalProject ? "Progetto Finale" : "Corso Opzionale"}
            id={container.id}
            key={"label-container-" + i + "container-id-" + container.id}
          >
            {container.details.map((pathGuide, i) => {
              const pic_url = pathGuide.guide.image
                ? pathGuide.guide.image.url
                : "https://via.placeholder.com/150";
              return (
                <CardGuide
                  key={"guide-item-" + pathGuide.guide.id}
                  pic_url={pic_url}
                  master_topic={topic}
                  guide={pathGuide.guide}
                  requirements={pathGuide.requirements}
                  isModalPathDetails={false}
                  guideTypeId={container.type.id}
                  isOpened={openFirstGuideCard && i === 0}
                  isFinalProject={isFinalProject}
                />
              );
            })}
          </ContainerWithLabel>
        );
      })}
    </Row>
  );
};

GuideCardList.propTypes = {
  guides: PropTypes.arrayOf(PropTypes.object),
};

export default GuideCardList;
