import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CardImg, CardText, CardTitle, Col, Row } from 'reactstrap';
import { FaPlus, FaMinus } from 'react-icons/fa';

import CourseProgressDetails from '../CardCourse/CourseProgressDetails';
import CourseCircularProgress from '../CardCourse/CourseCircularProgress';

import Requirements from './Requirements';
import Skills from './Skills';
import CoachesList from './CoachesList';
import CardGuideStats from './CardGuideStats';
import './style.css';


const CardGuide = ({
	isModalPathDetails,
	guideTypeId, // is a final project container?
	pic_url,
	master_topic,
	isFinalProject,
	guide: {
		id,
		title,
		slug,
		topic,
		duration_guide,
		duration_project,
		max_points_guide,
		project_groups_count,
		description,
		skills,
		coaches,
		progress
	},
	requirements=[],
	isOpened=false
}) => {

	const [isCollapsed, setIsCollapsed] = useState(true);

	// console.log(id)
	// console.log(title)
	// console.log('confronting with: 85561517-0834-4772-b935-a6ecf55bd601');
	// console.log(id==='85561517-0834-4772-b935-a6ecf55bd601');

	return (
		<>
			{ /* guide types label  === 'scegli un corso tra' ? ... */ }
			<div className={`guide-card card-horizontal p-2 ${guideTypeId==='d6bf37d8-6cd9-40ae-81ca-ee32f0782be1' ? 'border mb-3' : ''}`}>
				
				<Row>

					<Col xs={12} md={isModalPathDetails?12:8}>
						
						{/* <!-- MOBILE version --> */}
						<div className='row d-md-none d-block'>
							<div className='row'>
								<div className='col-3'>
									<CardImg src={'https://res.cloudinary.com/start2impact/image/fetch/'+pic_url} alt={title} className={`card-img-left ${guideTypeId==='d6bf37d8-6cd9-40ae-81ca-ee32f0782be1' && 'ms-2'}`} />
								</div>
								<div className={(isModalPathDetails?'col-9 ps-2':'col-7') +' my-auto ps-1'}>
									<CardTitle tag='h4' className='text-wrap'>{title}</CardTitle>
								</div>
								{
									// bottone apri/chiudi card visibile solo in homepage
									!isModalPathDetails &&
										<div className='col-1 my-auto text-end'>
											<Button onClick={()=>setIsCollapsed(!isCollapsed)} color='link' className='text-secondary guide-card-collapse-btn'>
												{isCollapsed ? <FaPlus /> : <FaMinus />}
											</Button>
										</div>
								}
							</div>
							{
								isModalPathDetails && topic && (
									<div className='col-md-3 text-start pt-3'>
										<span className="badge" style={{
											color: topic.color,
											border: `1px solid ${topic.color}`
										}}>
											{topic.name}
										</span>
									</div>
								)
							}
							<div className="col-12">
								<CardGuideStats
									studentCompletedGuide={progress?.projects_groups_done>0}
									duration_guide={duration_guide}
									duration_project={duration_project}
									max_points_guide={max_points_guide}
									test_points={progress?.test_passed_points}
									test_max_points={progress?.test_max_points}
									projects_count={project_groups_count}
									projects_completed={progress?.projects_groups_done}
									projects_points={progress?.projects_points}
								/>
							</div>
						</div>

						{/* <!-- DESKTOP version --> */}
						<div className='row d-none d-md-flex align-items-center'>
							<div className='col-md-1 p-2'>
								<CardImg src={'https://res.cloudinary.com/start2impact/image/fetch/'+pic_url} alt={title} className={`card-img-left ${guideTypeId==='d6bf37d8-6cd9-40ae-81ca-ee32f0782be1' && 'ml-2'}`} />
							</div>
							<div className={(isModalPathDetails && topic) ? 'col-md-8' : 'col-md-10'}>
								<div className="row">
									<div className="col-md-12">
										<CardTitle tag='h4' className='text-truncate'>{title}</CardTitle>
									</div>
									<div className="col-md-12">
										<CardGuideStats
											studentCompletedGuide={progress?.projects_groups_done>0}
											duration_guide={duration_guide}
											duration_project={duration_project}
											max_points_guide={max_points_guide}
											test_points={progress?.test_passed_points}
											test_max_points={progress?.test_max_points}
											projects_count={project_groups_count}
											projects_completed={progress?.projects_groups_done}
											projects_points={progress?.projects_points}
										/>
									</div>
								</div>
							</div>
							{
								isModalPathDetails && topic && (
									<div className='col-md-3 text-end'>
										<span className="badge" style={{
											color: topic.color,
											border: `1px solid ${topic.color}`
										}}>
											{topic.name}
										</span>
									</div>
								)
							}
						</div>

					</Col>

					{
						// circolar graphs in homepage with closed card
						(!isModalPathDetails && isCollapsed) && (
							<Col xs={9} md={3} className='my-auto'>
								<CourseCircularProgress
									theory_progress={progress?.theory_progress}
									test_points={progress?.test_passed_points}
									test_retries={progress?.test_retries}
									test_blocked_until={progress?.test_blocked_until}
									project_bloked_until={progress?.project_blocked_until}
									project_retries={progress?.project_retries}
									project_resend={progress?.project_resend}
									project_sentnotcorrected={progress?.project_sentnotcorrected}
									projects_count={project_groups_count}
									projects_completed={progress?.projects_groups_done}
									projects_points={progress?.projects_points}
									isFinalProject={guideTypeId==='1140639d-bbc7-47c7-9477-a2218daf02f2' && id!=='85561517-0834-4772-b935-a6ecf55bd601'} // IsFinalProject?'Progetto Finale' OR guideId is "Shopify Advanced"
								/>
							</Col>
						)
					}

					{
						// open/close button - card visible only on homepage
						!isModalPathDetails &&
							<Col xs={3} md={isCollapsed?1:4} className='my-auto d-md-block d-none text-end'>
								<Button onClick={()=>setIsCollapsed(!isCollapsed)} color='link' className='text-secondary guide-card-collapse-btn'>
									{isCollapsed ? <FaPlus /> : <FaMinus />}
								</Button>
							</Col>
					}

				</Row>



				{
					// Homepage: guide card openend (details)
					!isCollapsed && (
						<>
							<CardText className='card-description px-3' dangerouslySetInnerHTML={{__html:description}}></CardText>
							<div className="row align-items-center">
								{
									skills.length>0 && (
										<>
											<hr />
											<div className="col-12 col-md-6">
												<Skills values={skills} />
											</div>
										</>
									)
								}
								 { 
									requirements.length>0 && (
										<div className="col-12 col-md-6 pt-3">
											<Requirements isFinalProject={isFinalProject} values={requirements} />
										</div>
									)
								}
							</div>
							<hr className='border' />
							<CourseProgressDetails
								keyId={'progress_details_guide_id_'+id}
								guidePath={`/corsi/${slug}`} // /topics/${master_topic.slug}
								showDetails={!isCollapsed}
								theory_progress={progress?.theory_progress}
								test_points={progress?.test_passed_points}
								test_max_points={progress?.test_max_points}
								test_retries={progress?.test_retries}
								test_blocked_until={progress?.test_blocked_until}
								project_blocked_until={progress?.project_blocked_until}
								project_retries={progress?.project_retries}
								project_resend={progress?.project_resend}
								project_sentnotcorrected={progress?.project_sentnotcorrected}
								projects_count={project_groups_count}
								projects_completed={progress?.projects_groups_done}
								projects_points={progress?.projects_points}
								isFinalProject={guideTypeId==='1140639d-bbc7-47c7-9477-a2218daf02f2' && id!=='85561517-0834-4772-b935-a6ecf55bd601'} // IsFinalProject?'Progetto Finale' OR guideId is "Shopify Advanced"
							/>
						</>
					)
				}
			</div>
			{
				// Paths Modal: card always open
				isModalPathDetails &&
					<>
						<CardText className='card-description'>
							<span dangerouslySetInnerHTML={{__html:description}}></span>
						</CardText>
						<hr />
						<div className="row">
							<div className="col-md-4 col-12">
								<CourseCircularProgress
									theory_progress={progress?.theory_progress}
									test_points={progress?.test_passed_points}
									test_retries={progress?.test_retries}
									test_blocked_until={progress?.test_blocked_until}
									project_bloked_until={progress?.project_blocked_until}
									project_resend={progress?.project_resend}
									project_retries={progress?.project_retries}
									project_sentnotcorrected={progress?.project_sentnotcorrected}
									projects_count={project_groups_count}
									projects_completed={progress?.projects_groups_done}
									projects_points={progress?.projects_points}
									isFinalProject={guideTypeId==='1140639d-bbc7-47c7-9477-a2218daf02f2' && id!=='85561517-0834-4772-b935-a6ecf55bd601'} // IsFinalProject?'Progetto Finale' OR guideId is "Shopify Advanced"
								/>
							</div>
							<div className="col-md-8 col-12 align-self-center">
								{/* { console.log(coaches) } */}
								{ coaches?.length > 0 && <CoachesList coaches={coaches} />}
							</div>
						</div>
					</>
			}
		</>
	);
};

CardGuide.propTypes = {
	id: PropTypes.string,
    pic_url: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	skills: PropTypes.arrayOf(PropTypes.object),
	prerequisites: PropTypes.arrayOf(PropTypes.string),
	isModalPathDetails: PropTypes.bool,
	// coaches: PropTypes.arrayOf(PropTypes.object)
};

export default CardGuide;
