import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	all_notifications_as_read: false,
	error: null,
	status: 'idle'
};



const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		// read the notification list
		notificationsFetchRequest(state, action) {
			state.status = 'loading';
		},
		notificationFetchSuccess(state, action) {
			state.data = action.payload; // action.payload.sort((a, b) => b.date.localeCompare(a.date))
            state.status = 'completed';
		},
		notificationFetchFail(state, action) {
			state.error = action.payload;
            state.status = 'error';
		},
		// set all notification as read
		setAllNotificationsAsReadRequest(state, action) {
			state.status = 'loading';
		},
		setAllNotificationsAsReadSuccess(state, action) {
			state.all_notifications_as_read = true;
			state.status = 'completed';
		},
		setAllNotificationsAsReadFail(state, action) {
			state.error = action.payload;
            state.status = 'error';
		},
		// set a single notification as read
		setNotificationAsReadRequest(state, action) {
			state.status = 'loading';
		},
	},
});


export const {
	notificationsFetchRequest, notificationFetchSuccess, notificationFetchFail,
	setNotificationAsReadRequest, setNotificationAsReadSuccess, setNotificationAsReadFail,
	setAllNotificationsAsReadRequest, setAllNotificationsAsReadSuccess, setAllNotificationsAsReadFail
} = notificationsSlice.actions;
export default notificationsSlice.reducer;