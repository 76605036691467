import React, { useState } from 'react';
import Cookies from 'js-cookie';
import useAuth from "../../context/authContext";

import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody,	ModalFooter, Row, Col, Label, Input } from 'reactstrap';

import VideoPlayer from '../VideoPlayer';

import { calculateDaysFromToday, isSameDay, isAfter } from '../../helpers/dates.js';
import config from '../../config.js';



const ModalWelcomeVideo = () => {

    const { session } = useAuth();

    const [modal, setModal] = useState(true);
    const toggle = () => {
        handleLastVideoSeen(new Date());
        setModal(!modal);
    };

    const [neverShowAgain, setNeverShowAgain] = useState(Cookies.get('welcome_video') || false);
    const handleDontSeeVideoAgain = neverSeeItAgain => {
        setNeverShowAgain(neverSeeItAgain);
        neverSeeItAgain
            ? Cookies.set('welcome_video', true) // expires 8 days after today
            : Cookies.remove('welcome_video');
    }
    const handleLastVideoSeen = date => {
        Cookies.set('welcome_video_last_seen', date); // expires 8 days after today
    }

    // Checks for showing the video
    const dontShowVideoAgain = Cookies.get('welcome_video') || false;
    // const newUserRegistration = calculateDaysFromToday(session?.user?.created_at) <= config.daysShowWelcomeVideoAfterRegistration;
    const isSameDayLastSeen = isSameDay(Cookies.get('welcome_video_last_seen'),new Date());

    // console.log('dontShowVideoAgain'+dontShowVideoAgain);
    // console.log(session);
    // console.log('newUserRegistration: '+calculateDaysFromToday(session?.user.created_at));
    // console.log('isSameDayLastSeen'+isSameDayLastSeen);
    
    // console.log(dontShowVideoAgain===false && (newUserRegistration && !isSameDayLastSeen));
    // console.log('dontShowVideoAgain'+dontShowVideoAgain);
    // console.log('newUserRegistration'+newUserRegistration);
    // console.log('isSameDayLastSeen'+isSameDayLastSeen);
    if (dontShowVideoAgain===false && !isSameDayLastSeen && (session.user.email!=='alessandrolorrai@gmail.com') )
        return (
            <Modal isOpen={modal} toggle={toggle} contentClassName="" size='xl'>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody className={'pb-0'}>
                    <Row>
                        <Col xs={12}>
                            <VideoPlayer
                                playsInline
                                poster='https://res.cloudinary.com/start2impact/image/upload/v1662486172/video/video_poster_yh6igb.jpg'
                                src='https://assets.start2impact.it/video_onboarding_07092022.mp4'
                                onVideoStart={() => handleLastVideoSeen(new Date())}
                                onVideoEnd={() => handleDontSeeVideoAgain(true)}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className={'border-0'}>
                    <Label check className="mx-auto">
                        <Input
                            type="checkbox"
                            checked={neverShowAgain}
                            onChange={e => handleDontSeeVideoAgain(!neverShowAgain)}
                            //console.log(e.target.checked); // true || false
                        />
                        Non mostrare pi&ugrave;
                    </Label>
                </ModalFooter>
            </Modal>
        )
    else return null;
};

ModalWelcomeVideo.propTypes = {
    poster: PropTypes.string,
}

export default ModalWelcomeVideo;