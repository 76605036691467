import React  from 'react';
import PropTypes from 'prop-types';
import { CardImg, CardText, CardTitle } from 'reactstrap';

import CourseCircularProgress from '../CourseCircularProgress';
import CoachesList from '../CoachesList';
import CardCourseStats from '../CardCourseStats';

import styles from "./style.module.css";


const CardCourseStatic = ({
	pic_url,
	isFinalProject,
	guide: {
		title,
		duration_guide,
		duration_project,
		max_points_guide,
		project_groups_count,
		description,
		coaches,
		progress,
		slug
	},
}) => {

	const coursePath = `/corsi/${slug.toLowerCase()}`

	return (
		<div className={`${styles.container}`}>
			<div className={`guide-card`}>
				{/* <!-- MOBILE version --> */}
				<div className='row d-md-none d-block' >
					<div className='row' >
						<div className='col-3'>
							<a href={coursePath} className={styles.linkButton}>
								<CardImg src={'https://res.cloudinary.com/start2impact/image/fetch/'+pic_url} alt={title} className={styles.cardImgLeft}/>
							</a>
						</div>
						<div className={('col-9 ps-2') +' my-auto ps-1'}>
							<a href={coursePath} className={styles.linkButton}>
								<CardTitle tag='h4' className='text-wrap'>{title}</CardTitle>
							</a>
						</div>
					</div>
					<div className="col-12 mb-4">
						<CardCourseStats
							studentCompletedGuide={progress?.projects_groups_done>0}
							duration_guide={duration_guide}
							duration_project={duration_project}
							max_points_guide={max_points_guide}
							test_points={progress?.test_passed_points}
							test_max_points={progress?.test_max_points}
							projects_count={project_groups_count}
							projects_completed={progress?.projects_groups_done}
							projects_points={progress?.projects_points}
						/>
					</div>
				</div>

				{/* <!-- DESKTOP version --> */}
				<div className='d-none d-md-flex align-items-center'>
					<div className='p-2 pe-3 ps-0'>
						<a href={coursePath} className={styles.linkButton}>
							<CardImg src={'https://res.cloudinary.com/start2impact/image/fetch/'+pic_url} alt={title} className={styles.cardImgLeft} />
						</a>
					</div>
					<div className=" flex-grow-1 flex-wrap">
						<div>
							<a href={coursePath} className={styles.linkButton}>
								<CardTitle tag='h4' className='text-truncate'>{title}</CardTitle>
							</a>
						</div>
							<CardCourseStats
								studentCompletedGuide={progress?.projects_groups_done>0}
								duration_guide={duration_guide}
								duration_project={duration_project}
								max_points_guide={max_points_guide}
								test_points={progress?.test_passed_points}
								test_max_points={progress?.test_max_points}
								projects_count={project_groups_count}
								projects_completed={progress?.projects_groups_done}
								projects_points={progress?.projects_points}
							/>
					</div>

					<div className={`text-end d-none d-xl-block ${styles.buttonContainer}`}>
						<a href={coursePath}>
							<button className={styles.button}>VAI AL CORSO</button>
						</a>
					</div>
				</div>
			</div>
				<>
					<CardText>
						<span dangerouslySetInnerHTML={{__html:description}} />
					</CardText>
					<hr className={styles.hr}/>
					<div className="row">
						<div className="col-md-4 col-12">
							<CourseCircularProgress
								theory_progress={progress?.theory_progress}
								test_points={progress?.test_passed_points}
								test_retries={progress?.test_retries}
								test_blocked_until={progress?.test_blocked_until}
								project_bloked_until={progress?.project_blocked_until}
								project_resend={progress?.project_resend}
								project_retries={progress?.project_retries}
								project_sentnotcorrected={progress?.project_sentnotcorrected}
								projects_count={project_groups_count}
								projects_completed={progress?.projects_groups_done}
								projects_points={progress?.projects_points}
								isFinalProject={isFinalProject}
							/>
						</div>
						<div className="col-md-8 col-12 align-self-center overflow-scroll">
							{ coaches?.length > 0 && <CoachesList coaches={coaches} />}
						</div>
					</div>
				</>
				<hr className={`d-xl-none ${styles.hr}`}/>
				<div className={`text-center d-xl-none py-2 ${styles.buttonContainer}`}>
					<a href={coursePath}>
						<button className={styles.button}>VAI AL CORSO</button>
					</a>
				</div>
		</div>
	);
};

CardCourseStatic.propTypes = {
    pic_url: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
};

export default CardCourseStatic;
