import React, { useState } from 'react';
import { bool, number, object, string } from 'prop-types';

import { Button, Row, Col, List, Tooltip } from 'reactstrap';
import {  FaTasks, FaInfoCircle } from 'react-icons/fa'
import { Doughnut } from 'react-chartjs-2';

import styles from "./style.module.css"
import progressColors from '../progressColors';
import { calculateDaysToToday } from '../../../helpers/dates';

const TestProgress = ({
    isMobile,
    theory_progress,
    test_points,
    test_retries,
    test_max_points,
    test_blocked_until,
    isFinalProject,
    guidePath,
    doughnutSetting
}) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const testDetails = (theory_progress, test_points, test_retries, test_max_points) => {
        
        let textCopy = null;
        let isButtonActive = true;
        let iconCssBackground = null;
        let circleBackgroundColor = progressColors.inactiveBackgroundColor;
        let backendTestBlockAfterNRetries = 14;

        let testDaysBlock = calculateDaysToToday(test_blocked_until);

        if (test_points>0) {                                                                                           // test superato: Test - 270/max
            textCopy = `Test - ${test_points}/${test_max_points}`;
            iconCssBackground = styles.iconGreen;
            circleBackgroundColor = context => progressColors.activeBackgroundColor(context);
            isButtonActive = false;
        }
        else if (test_blocked_until!==null && testDaysBlock>0) {                                                       // Test bloccato
            textCopy = `Test non superato<br />Potrai riprovare tra ${testDaysBlock} giorni`;
            iconCssBackground = styles.iconRed;
            circleBackgroundColor = context => progressColors.blockedBackgroundColor;
            isButtonActive = false;
        }
        else if (test_blocked_until!==null && testDaysBlock<=0) {                                                      // Test sbloccato dopo periodo di blocco
            textCopy = `Test non superato<br />Riprova!`;
            iconCssBackground = styles.iconRed;
            circleBackgroundColor = context => progressColors.blockedBackgroundColor;
            isButtonActive = true;
        }
        else if (test_retries===2) {                                                                                    // Test non superato, 1 solo tentativo rimanente
            textCopy = `Test non superato<br />1 tentativo rimanente`;
            iconCssBackground = styles.iconRed;
            circleBackgroundColor = context => progressColors.blockedBackgroundColor;
            isButtonActive = true;
        }
        else if (test_retries===1) {                                                                                    // Test non superato, N tentativi rimanenti
            textCopy = `Test non superato<br />2 tentativi rimanenti`;
            iconCssBackground = styles.iconRed;
            circleBackgroundColor = context => progressColors.blockedBackgroundColor;
            isButtonActive = true;
        }
        else if (theory_progress<100) {                                                                                 // Teoria NON completata
            textCopy = 'Il test sarà disponibile una volta completata la teoria';
            iconCssBackground = styles.iconGray;
            circleBackgroundColor = context => progressColors.inactiveBackgroundColor;
            isButtonActive = false;
        }
        else if (theory_progress===100 && test_retries===0) {
            textCopy = 'Test da completare';                                                                            // Teoria completata, test NON svolto
            iconCssBackground = styles.iconGray;
            circleBackgroundColor = context => progressColors.inactiveBackgroundColor;
            isButtonActive = true;
        }
        else { textCopy = `Questo non era previsto! Ce lo segnali? :)`; }                                               // bug
        return {
            text   : textCopy,
            button : isButtonActive,
            colors : {
                iconCssBackground,
                circleBackgroundColor
            },
            backendTestBlockAfterNRetries
        }
    }

    const testData = {
        maintainAspectRatio: true,
        responsive : true,
        labels     : [ testDetails(theory_progress, test_points, test_retries, test_max_points).text ],
        colors     : testDetails(theory_progress, test_points, test_retries, test_max_points).colors,
        datasets   : [
            {
                data: [100],
                backgroundColor  : context => [ testDetails(theory_progress, test_points, test_retries, test_max_points).colors.circleBackgroundColor(context) ],
                hoverBorderColor : ['transparent'] 
            },
        ]
    };

    return (
        !isMobile ? (
            !isFinalProject && (
                <Col className="mb-lg-0">
                    <div className={`${styles.homeGuideChart}`}>
                        <Row className={`${styles.chartRow} no-gutters align-items-center`}>
                            <Col lg={3} sm={2} md={4}>
                                <div className="chart-container">
                                    <Doughnut data={testData} options={doughnutSetting} className="chart" width={80} />
                                    <div>
                                        <FaTasks className={`${styles.chartIcon} ${testData.colors.iconCssBackground}`} />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={9} sm={10} md={8} className="text-lg-left">
                                <List type="unstyled">
                                    {
                                        testData['labels'].map( (label, i) => (
                                            <li key={'doughnut_test_key_'+i}>
                                                <div className="row">
                                                    <div className="col-10" dangerouslySetInnerHTML={{__html:label}}></div>
                                                    <div className="col-2 p-0">
                                                        {
                                                            test_retries >0 && test_retries <3 && test_points === 0 && (
                                                                <>
                                                                    <FaInfoCircle id={'tooltip_test_key_'+i} class='icon-tooltip-project-blocked' />
                                                                    <Tooltip 
                                                                        target={'tooltip_test_key_'+i}
                                                                        placement="right"
                                                                        toggle={toggleTooltip}
                                                                        isOpen={tooltipOpen}
                                                                    >
                                                                        Dopo che finirai i tentativi dovrai attendere {testDetails(theory_progress, test_points, test_retries, test_max_points).backendTestBlockAfterNRetries} giorni per tentare nuovamente questo test
                                                                    </Tooltip>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                    }
                                </List>
                            </Col>
                        </Row>
                        {
                            testDetails(theory_progress, test_points, test_retries, test_max_points).button
                            ? <a className={`${styles.chartCardButton} ${styles.buttonActive}`} href={guidePath+'#test'}>VAI AL TEST</a>
                            : <a href='#test' className={`${styles.chartCardButton} ${styles.buttonInactive}`}>VAI AL TEST</a>
                        }
                    </div>
                </Col>
            )
        ) : (
            !isFinalProject && (
                <div className={`${styles.homeGuideChart}`}>
                    <Row className={`mb-3 p-2 ${styles.chartRow} no-gutters align-items-center justify-content-center`}>
                        <Col xs={3} className={"p-0"}>
                            <div className={styles.chartContainer}>
                                <Doughnut data={testData} options={doughnutSetting} className="chart" width={80} />
                                <div>
                                <FaTasks className={`${styles.chartIcon} ${testData.colors.iconCssBackground}`} />
                                </div>
                            </div>
                        </Col>
                        <Col xs={9} className={`text-lg-left p-0 ${styles.collapseLabel}`}>
                            {
                                test_retries > 0 && test_retries < 3 && test_points === 0
                                ? (
                                    <List type="unstyled">
                                        {
                                            testData['labels'].map( (label, i) => (
                                                <li key={'doughnut_test_key_'+i}>
                                                    <div className="row">
                                                        <div className="col-10" dangerouslySetInnerHTML={{__html:label}}></div>
                                                        <div className="col-2 p-0">
                                                            {
                                                                test_retries>0 && test_retries <3 && test_points===0 && (
                                                                    <>
                                                                        <FaInfoCircle id={'tooltip_test_key_'+i} class='icon-tooltip-project-blocked' />
                                                                        <Tooltip 
                                                                            target={'tooltip_test_key_'+i}
                                                                            placement="right"
                                                                            toggle={toggleTooltip}
                                                                            isOpen={tooltipOpen}
                                                                        >
                                                                            Dopo che finirai i tentativi dovrai attendere {testDetails(theory_progress, test_points, test_retries, test_max_points).backendTestBlockAfterNRetries} giorni per tentare nuovamente questo test
                                                                        </Tooltip>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </List>
                                )
                                :
                                    (
                                        <List type="unstyled">
                                            <li key={'doughnut_test_key_1'}>
                                                <div dangerouslySetInnerHTML={{__html:testData['labels'][0]}}></div>
                                            </li>
                                        </List>)
                            }
                        </Col>
                    </Row>
                    {
                        testDetails(theory_progress, test_points, test_retries, test_max_points).button
                        ? <a className={`btn ${styles.chartCardButton} ${styles.buttonActive}`} href={guidePath+'#test'}>VAI AL TEST</a>
                        : <Button className={`btn ${styles.chartCardButton}`} disabled>VAI AL TEST</Button>
                    }
                </div>
            )
        )
    )
}

TestProgress.propTypes = {
	isMobile: bool.isRequired,
    test_max_points: number.isRequired,
    test_points: number.isRequired,
    test_retries: number.isRequired,
    theory_progress: number.isRequired,
    guidePath: string.isRequired,
    isFinalProject: bool.isRequired,
    doughnutSetting: object.isRequired,
};

export default TestProgress;