import { put, takeEvery, call } from 'redux-saga/effects';
import { topicsFetchRequest, topicsFetchSuccess, topicsFetchFail } from '../slices/topics';

import { topicsApi } from '../api/topics';
//import { topics } from '../fakedb';



function* fetchTopicsSaga({payload: { user_id }}) {
	try {
		const response = yield call(topicsApi.studentTopics, user_id);
		yield put(topicsFetchSuccess(response.data.entities.topics.items.data));
	} catch (error) {
		yield put(topicsFetchFail('There was an error trying to get the questions. Please refresh the page!'));
	}
}

export default function* topicsSaga() {
	yield takeEvery(topicsFetchRequest.toString(), fetchTopicsSaga);
}
