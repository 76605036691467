import { createAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	error: null,
	status: 'idle'
};



const workingSectorSlice = createSlice({
	name: 'working_sectors',
	initialState,
	reducers: {
		fetchWorkingSectorsSuccess(state, action) {
			state.data = action.payload;
			state.status = 'completed';
		},
		fetchWorkingSectorsFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		},
	},
});


export const fetchWorkingSectorsRequest = createAction('fetch_working_sectors_request');
export const { fetchWorkingSectorsSuccess, fetchWorkingSectorsFail } = workingSectorSlice.actions;
export default workingSectorSlice.reducer;