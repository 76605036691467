import React, { useEffect, useState, useRef } from "react";

import { useLocation, NavLink } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import useAuth from "../../context/authContext";

import config from "../../config";

import { Scrollbars } from "rc-scrollbars";
import Logo from "../Logo";
import UpsellingTab from "./UpsellingTab";

import { Nav } from "reactstrap";
import { FaFireAlt, FaMedal, FaGift } from "react-icons/fa";
import { BsList } from "react-icons/bs";
import { AiOutlineMenuFold } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

import "./style.css";

const SideBar = ({ menuItems, userSession, userRecord }) => {
  // console.log('window pathname: '+window.location.pathname)
  const sidebarRef = useRef(null);

  const handleDesktopMenu = (isCollapsed) => {
    setIsCollapsed(isCollapsed);
    if (isCollapsed) {
      document.body.classList.remove("body-with-sidebar");
      document.body.classList.add("body-without-sidebar");
    } else {
      document.body.classList.add("body-with-sidebar");
      document.body.classList.remove("body-without-sidebar");
    }
  };

  const closeMobileUIMenu = (ref) => {
    //document.getElementById("sidebar-menu")
    return ref.classList.add("collapsed");
  };

  const [isCollapsed, setIsCollapsed] = useState(false);

  // Retrive account information on each page refresh
  //const dispatch = useDispatch();
  const location = useLocation();
  const [windowHeight, setWindoHeight] = useState(0);

  useEffect(() => {
    setWindoHeight(window.innerHeight); // set window height
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile) {
      document.body.classList.remove("body-with-sidebar");
      document.body.classList.remove("body-without-sidebar");
      closeMobileUIMenu(sidebarRef.current); // menu is closed by default when isMobile
    } else {
      document.body.classList.remove("body-without-sidebar");
      document.body.classList.add("body-with-sidebar"); // add left margin to the page
    }
  }, [location]);

  return (
    <div
      ref={sidebarRef}
      id="sidebar-menu"
      className={
        isMobile
          ? "sidebar sidebar-mobile collapsed"
          : isCollapsed
          ? "sidebar sidebar-desktop collapsed"
          : "sidebar sidebar-desktop"
      }
    >
      <Scrollbars autoHide hideTracksWhenNotNeeded>
        <div className="sidebar-header">
          <>
            {!isMobile && (
              <button
                onClick={() => handleDesktopMenu(!isCollapsed)}
                className="btn-close-menu d-sm-none d-md-block"
              >
                {isCollapsed ? <BsList /> : <AiOutlineMenuFold />}
              </button>
            )}
            {isMobile && (
              <button
                onClick={() => closeMobileUIMenu(sidebarRef.current)}
                className="btn-close-menu"
              >
                <IoClose />
              </button>
            )}
          </>
          <Logo isCollapsed={isCollapsed} basepath={config.basepath} />
          <div className="small-profile">
            <div className="avatar text-center">
              <a
                href={"/profile/" + userRecord?.slug}
                target="_self"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    userRecord.pic_profile?.url ||
                    `https://res.cloudinary.com/start2impact/image/upload/v1666605907/Piattaforma/Img_Profilo-04_envhln.svg`
                  }
                  alt={`Foto profilo di ${userRecord.firstname}`}
                  className="photo"
                />
              </a>
            </div>
            {!isCollapsed && (
              <h6 className="name text-center">{`${
                userRecord?.firstname || userSession?.firstname
              } ${userRecord?.lastname || userSession?.lastname}`}</h6>
            )}
            <div className={"meta-info"}>
              {/* <span className='meta-item'>
                      {isCollapsed === false && <AiOutlineTrophy className='meta-icon'/> }
                      <span className='meta-label'>{userSession.ranking}°</span>
                    </span> */}
              <span className="meta-item">
                {!isCollapsed && <FaMedal className="meta-icon" />}
                <span className="meta-label">
                  {userRecord?.points || userSession?.points || 0} pt
                </span>
              </span>
            </div>
            <div className="days-passed">
              <FaFireAlt className="days-passed-icon" />{" "}
              <span>
                {userRecord?.consecutive_sign_in_count ||
                  userSession?.consecutive_days ||
                  0}{" "}
                {isCollapsed === false && (
                  <span> giorni consecutivi su start2impact!</span>
                )}
              </span>
            </div>
            {userSession?.points >= 500 && (
              <div className="text-center mb-sm-0 m-4 mt-4">
                <a
                  id="btn-invite-friends"
                  className="btn align-items-center text-decoration-none"
                  href="/invitations"
                >
                  <FaGift className="gift-icon" />{" "}
                  {!isCollapsed && <span>INVITA UN AMICO</span>}
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="main-menu">
          <Nav vertical>
            {menuItems.map((itemMenu) =>
              itemMenu.isInternal ? (
                <NavLink
                  key={`key_menu_item_${itemMenu.id}`}
                  exact
                  to={() => {
                    return itemMenu.to;
                  }}
                  target="_self"
                  activeClassName="active"
                  className="sidebar-items"
                >
                  <itemMenu.icon className="menu-icon" />{" "}
                  <span className="menu-label">{itemMenu.name}</span>
                </NavLink>
              ) : (
                // demo@aziende.it can't see users ranking
                (userRecord.id !== "8db7dce0-49ce-4b02-8eda-f6ac0993d83f" ||
                  itemMenu.id !== 4) && (
                  <a
                    key={`key_menu_item_${itemMenu.id}`}
                    href={itemMenu.to}
                    className="sidebar-items"
                  >
                    <itemMenu.icon className="menu-icon" />{" "}
                    <span className="menu-label">{itemMenu.name}</span>
                  </a>
                )
              )
            )}
          </Nav>
        </div>
        {userSession?.plan_slug === "m1-o26" && <UpsellingTab />}
      </Scrollbars>
    </div>
  );
};

export default SideBar;
