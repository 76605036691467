import React from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import './style.css';


const EditableCard = ({ title, button=false, body=false, className }) => {
	return (
		<Card className={`editable-card ${className}`}>
			<CardHeader>
				<Row className='align-items-center'>
					<Col xs={button?8:12}>
						{!!title && (
							<CardTitle className='title-header mb-0'>
								{title}
							</CardTitle>
						)}
					</Col>
					<Col xs={4} className='text-end'>
						{!!button && button}
					</Col>
				</Row>
			</CardHeader>
			<div className='px-0 w-100'>
				<CardBody>{!!body && body}</CardBody>
			</div>
		</Card>
	);
};

EditableCard.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string
}

export default EditableCard;
