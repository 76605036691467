import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
	data: [],
    error: null,
    status: "idle" // "idle", "loading", "completed"
};


const badgesSlice = createSlice({
	name: 'badges',
	initialState,
	reducers: {
		fetchBadgesRequest(state){
			state.status = 'loading';
		},
		fetchBadgesSuccess(state, action){
			state.data = action.payload;
			state.status = 'completed';
		},
		fetchBadgesFailed(state, action){
			state.error = action.payload;
			state.status = 'idle';
		},
        setBadgeHasBeenSeenRequest(state) {
			state.status = 'loading';
		},
		setBadgeHasBeenSeenSuccess(state, action) {
			state.data.splice(-1);
			state.status = 'completed';
		},
		setBadgeHasBeenSeenFail(state, action) {
			state.error = action.payload;
			state.status = 'idle';
		},
	},
});


export const {
    setBadgeHasBeenSeenRequest,
	setBadgeHasBeenSeenFail,
	setBadgeHasBeenSeenSuccess,
	fetchBadgesFailed, 
	fetchBadgesRequest, 
	fetchBadgesSuccess
} = badgesSlice.actions;

export default badgesSlice.reducer;
