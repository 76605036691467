import React from "react";
import { bool, func, string, PropTypes} from "prop-types";

import { Modal } from "reactstrap";
import { IoClose } from "react-icons/io5";

import styles from "./style.module.css";

const cloudinaryPath = "https://res.cloudinary.com/start2impact/image/fetch/"

const PopupBadge = ({
    userId,
    badge: { 
        title, 
        description, 
        picUrl,
    } = {}, 
    isOpen,
    togglePopup,
    action = null,
    actionLabel = "OK",
    ...restProps
}) => {

    return (
    
        <Modal centered isOpen={isOpen} toggle={togglePopup} className={styles.container} {...restProps}>
            <div style={action && {paddingBottom: 32}} className={styles.popupBody}>
                <button onClick={togglePopup} className={styles.closeIcon}>
                    <IoClose size={32} color={"#FF5A5F"}/>
                </button>

                <div className={styles.imageContainer}>
                    <img 
                        src={cloudinaryPath + picUrl ? `https://talent.start2impact.it${picUrl}`:'https://via.placeholder.com/150'}
                        width={120} 
                        height={120} 
                        alt={`Immagine del badge ${title}`}
                    />
                </div>

                <h4 className={styles.title}>{title}</h4>
                <span className={styles.description} dangerouslySetInnerHTML={{__html:description}} />
            </div>

            {action && 
                <div className={styles.popupFooter}>
                    <button className="btn-custom-light" onClick={togglePopup}>
                        Cancella
                    </button>
                    <button className="btn-custom-dark" color="secondary" onClick={() => action()}>
                        {actionLabel}
                    </button>
                </div>
            }
      </Modal>
        
    )
}

PopupBadge.propTypes = {
    isOpen: bool.isRequired,
	togglePopup: func.isRequired,
    badge: PropTypes.shape({
        title: string,
        description: string,
        img: string
      }),
    action: func,
    actionLabel: string,
}

export default PopupBadge;