import { all } from 'redux-saga/effects';

import topicsSaga from './sagas/topics';
import studentSaga from './sagas/student';
import workingSectorsSaga from './sagas/workingSectors';
import onuObjectivesSaga from './sagas/onuObjectives';
import notificationsSaga from './sagas/notifications';
import authenticationSaga from './sagas/authentication';
import accountSaga from './sagas/account';
import coursesSaga from "./sagas/courses";
import studentBadgeSaga from './sagas/badge';


export default function* rootSaga() {
	yield all([topicsSaga(), studentSaga(), workingSectorsSaga(), onuObjectivesSaga(), notificationsSaga(), authenticationSaga(), accountSaga(), coursesSaga(), studentBadgeSaga(), ]);
}
