import { put, takeEvery, call } from 'redux-saga/effects';
import { 
	hadAccessRequest, hadAccessSuccess, hadAccessFail,
	isSubscriptionOkRequest, isSubscriptionOkSuccess, isSubscriptionOkFail
 } from '../slices/authentication';

import { authenticationApi } from '../api/authentication';


function* hadAccessRequestSaga({ payload:{user_id} }) {
	try {
		const response = yield call(authenticationApi.hadAccess, user_id);
		const lastAccess = new Date();
		yield put(hadAccessSuccess(response?.data?.message==="Success"?lastAccess:null));
	}
	catch (error) {
		yield put(hadAccessFail(error));
	}
}

function* isSubscriptionOkRequestSaga({ payload:{user_id} }) {
	try {
		const response = yield call(authenticationApi.isSubscriptionOk, user_id);
		yield put(isSubscriptionOkSuccess(response));
	}
	catch (error) {
		yield put(isSubscriptionOkFail(error));
	}
}


export default function* authenticationSaga() {
	yield takeEvery(hadAccessRequest, hadAccessRequestSaga);
	yield takeEvery(isSubscriptionOkRequest, isSubscriptionOkRequestSaga);
}