import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';



const Requirements = ({values=[], isFinalProject}) => {

	return (
		<Col xs={12} md={6}>
			<h6 className='requirements-label'>Prerequisiti Necessari</h6>
			<div className='tag-container color'>


			{
				isFinalProject ? 
					<span className='badge requirements-final-project'>Tutti i Corsi obbligatori inclusi nel Master</span> 
				:
					values.length > 0
				?
					values.map( requirement => {
						return (
							<a key={requirement.id} href={`/corsi/${requirement.slug}`} style={{textDecoration:"none", color:"inherit"}}>
								<span className='badge me-1' style={{
									color: requirement.topic.color,
									border: `1px solid ${requirement.topic.color}`
								}}>
									{requirement.title}
								</span>
							</a>
						)
					})
				:
					<span key={'no_requirements'} className='tag-requirement'>
						Nessuno
					</span>
			}

			</div>
		</Col>
	);
};

Requirements.propTypes = {
	values: PropTypes.arrayOf(PropTypes.object),
};

export default Requirements;
