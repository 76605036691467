import { put, takeEvery, call } from 'redux-saga/effects';
import {
	studentFetchRequest, studentFetchSuccess, studentFetchFail,
	setStudentMasterRequest, setStudentMasterFail,
	updateStudentWorkingSectorsSuccess, updateStudentWorkingSectorsFail,
	updateStudentOnuObjectivesSuccess, updateStudentOnuObjectivesFail
} from '../slices/student';

import { studentApi } from '../api/student';
//import { student } from '../../store/fakedb';

import config from './../../config.js';


function* fetchStudentSaga({payload: { user_id }}) {
	try {
		const response = yield call(studentApi.show, user_id);
		yield put(studentFetchSuccess(response.data.entities.student.item));
		//console.log(response)
	}
	catch (error) {
		if (error.response && (error.response.status === 404 || error.status === 404)) {
			return window.location.href = config.onboardingPath;
		}
		yield put(studentFetchFail('There was an error trying to get the request.'));
	}
}

function* setStudentMasterRequestSaga({ payload:{master_id,user_id} }) {
	try {
		const response = yield call(studentApi.associateMaster, user_id, {master_id});
		if (response.data) {
			window.location = config.basepath;
		 }
	}
	catch (error) {
		yield put(setStudentMasterFail('There was an error trying to get the request.'));
	}
}

function* updateStudentWorkingSectorsRequest({payload: { sectorsList, user_id }}) {
	try {
		const response = yield call(studentApi.updateListWorkingSectors, user_id, sectorsList);
		yield put(updateStudentWorkingSectorsSuccess(response.data.entities.working_sectors)); 
	}
	catch (error) {
		yield put(updateStudentWorkingSectorsFail('There was an error trying to get the request.'));
	}
}

function* updateStudentOnuObjectivesRequest({payload: { objectivesList, user_id }}) {
	try {
		const response = yield call(studentApi.updateListOnuObjectives, user_id, objectivesList);
		yield put(updateStudentOnuObjectivesSuccess(response.data.entities.onu_objectives)); 
	}
	catch (error) {
		yield put(updateStudentOnuObjectivesFail('There was an error trying to get the request.'));
	}
}


export default function* studentSaga() {
	yield takeEvery(studentFetchRequest, fetchStudentSaga);
	yield takeEvery(setStudentMasterRequest, setStudentMasterRequestSaga);
	yield takeEvery('update_student_working_sectors_request', updateStudentWorkingSectorsRequest);
	yield takeEvery('update_student_onu_objectives_request', updateStudentOnuObjectivesRequest);

}