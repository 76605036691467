import {Button} from "reactstrap";

import {GiHamburgerMenu} from "react-icons/gi";



const MobileToggleIcon = () => {
    
    const openUISidebar = () => {
        document.getElementById("sidebar-menu").classList.remove("collapsed");
    }

    return (
        <div className="pipporight">
            <Button color="primary" className='menu-toggle-btn me-md-2' onClick={() => openUISidebar()}>
                <GiHamburgerMenu className='menu-toggle-icon' />
            </Button>
        </div>
    );
}

export default MobileToggleIcon;