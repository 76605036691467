import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	error: null,
    status: 'idle'
};


const topicsSlice = createSlice({
	name: 'topics',
	initialState,
	reducers: {
		topicsFetchRequest(state, action) {
			state.status = 'loading';
		},
		topicsFetchSuccess(state, action) {
			state.data = action.payload;
			state.status = 'completed';
		},
		topicsFetchFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		},
	},
});


export const { topicsFetchRequest, topicsFetchSuccess, topicsFetchFail } = topicsSlice.actions;
export default topicsSlice.reducer;
