import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';

import useAuth from "../context/authContext";
import { Redirect } from 'react-router-dom';

import config from '../config.js';



export default function LayoutWithoutMenu({component: Component, ...rest}) { 

  const { session } = useAuth();
  
  useEffect(() => {
    if (!session) return <Redirect to={config.loginPath} />;
  }, [session]);

  return (
    <Route {...rest} render={ otherProps => (
      <>
       { session ? <Component userSession={session.user} {...otherProps} /> : <Component {...otherProps} /> }
      </>
    )} />
  );

}