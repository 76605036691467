import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { topicsFetchRequest, setStudentMasterRequest } from '../../store/slices';

import { isBrowser } from 'react-device-detect';
import { Helmet } from "react-helmet";

import { Container, Col, Row, Alert } from 'reactstrap';
import {Loader} from '../../components';
import CardPath from '../../components/CardPath';
import ModalMasterDetails from '../../components/ModalMasterDetails';
import MobileToggleIcon from "../../components/SidebarMenu/MobileToggleIcon";
import Footer from '../../components/Footer';

import './style.css';

import config from '../../config.js';



const getAllMasters = topics => topics.flatMap( topic => topic.paths);


const Masters = ({ userSession }) => {

	const dispatch = useDispatch();
	const location = useLocation();

	const { data:topics, status:topicsStatus } = useSelector(state => state.topicsSlice);

	const [mastersList, setMasters] = useState([]);
	const [selectedTopicId, setSelectedTopicId] = useState(null); // the topicId selected by the filter
	const [selectedMaster, setSelectedMaster] = useState(null); // the masterId to be showed in modal

	useEffect( () => {
		// if (typeof userSession === 'undefined') return window.location = config.loginPath;
		dispatch(topicsFetchRequest({user_id:userSession.id}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect( () => {
		setMasters(getAllMasters(topics));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topics]);

	const filterMasterByTopic = (topic_id=null) => {
		const mastersByTopic = topic_id!==null ? topics.find(topic => topic['id'] === topic_id).paths : getAllMasters(topics);
		setMasters(mastersByTopic);
		setSelectedTopicId(topic_id);
	}

	const [isModalOpen, setIsModalOpen] = useState(false);

	const toggleModal = (masterId=null, mastersList=[]) => {
		const selectedMaster = mastersList.find(master => master.id === masterId)
		setSelectedMaster(selectedMaster);
		setIsModalOpen(!isModalOpen);
	};

	const startNewMaster = (masterId=null) => {
		dispatch(setStudentMasterRequest({user_id:userSession.id, master_id:masterId}));
	}

    if (topicsStatus !== "completed") return <Loader />

	return (
		<>
			<Helmet>
				<title>{config.menuItems(userSession.plan_slug)[1].title}</title>
			</Helmet>

			<div className='masterSelectionPage pt-2'>
				<Container>
					<Row>
						{
							!isBrowser && (<MobileToggleIcon />)
						}
						{
							location.pathname === config.onboardingPath && (
								<div className='col-12 text-center'>
									<img src={`${config.basepath}images/logos/full.svg`} width="191" height="40" alt="start2impact University logo" />
								</div>
							)
						}
					</Row>
					<Row className='pt-3'>
						<Col xs={12} className='text-center'>
							<h1 className='page-title'>
								{ location.pathname === config.onboardingPath ? 'Scegli il tuo Master' : 'Seleziona un Master'}
							</h1>
						</Col>
						<Col xs={2} xl={0}>
						</Col>
						<Row>
							<Col xs={12} className='text-center'>
								<p className='page-subtitle'>Solo tu puoi sapere qual &egrave; il percorso più adatto a te.<br />Scegli quello pi&ugrave; affine alle tue passioni e ai tuoi interessi.<br />Potrai cambiarlo in qualsiasi momento, tutte le volte che vuoi.</p>
							</Col>
						</Row>
					</Row>
					<Row className='pb-3'>
						<Col xs={12}>
							<div className='tag-nav'>
								<span key={'all_topic'} className={`tag-item ${!selectedTopicId && 'active'}`}>
									<a href="#tutti-i-master" onClick={() => filterMasterByTopic(null)}>Tutti</a>
								</span>
								{
									topics.map( topic => {
										return (
											<span key={'topic_'+ topic.id} className={`tag-item ${selectedTopicId===topic.id && 'active'}`}>
												<a href={`#master-${topic.name.toLowerCase().split(' ').join('-')}`} onClick={() => filterMasterByTopic(topic.id)}>{topic.name}</a>
											</span>
										)
									})
								}
							</div>
						</Col>
					</Row>
					{
						mastersList.length > 0
						? (
							<Row>
								{
									mastersList.map( master => {
										//console.log(master);
										return (
											<Col key={'path_'+ master.id} xs={12} className='pb-2'>
												<CardPath
													topic={master.topic}
													toggleModal={()=>toggleModal(master.id, mastersList)}
													startNewPath={()=>startNewMaster(master.id)}
													{...master}
												/>
											</Col>
										)
									})
								}
							</Row>
						)
						: (<Alert>Nessun Master trovato.</Alert>)
					}
				</Container>
				{
					isModalOpen && (
						<ModalMasterDetails
							toggle={() => setIsModalOpen(false)}
							master={selectedMaster}
							startNewMaster={startNewMaster}
						/>
					)
				}
			</div>
			<Footer />
		</>
	);
};

export default Masters;
