import progressColors from "./progressColors";

const getProjectStatusColor = (context, projects_completed=0, project_sentnotcorrected=null, project_resend=false, project_blocked_until=null, project_retries=0, projects_count=0) => {
    let projectsColor = [];

    [...Array(projects_count)].map((project, index) => {
        if(projects_completed >= index + 1){

            projectsColor.push(progressColors.activeBackgroundColor(context));

        } else {

            // if the index is greater than zero, and completed projects are less than the current index
            if(index > 0 && projects_completed < index ) { 
                projectsColor.push(progressColors.inactiveBackgroundColor);
            } 
            
            // if project is sended but still not corrected
            else if(project_sentnotcorrected !== null){
                projectsColor.push(progressColors.waitingBackgroundColor);
            }     

            // if project must be resended 
            else if(project_resend === true){
                projectsColor.push(progressColors.resendBackgroundColor);
            } 
            
            // if project is blocked
            else if(project_blocked_until !== null || project_retries > 0){
                projectsColor.push(progressColors.blockedBackgroundColor);
            }
            
            else {
                projectsColor.push(progressColors.inactiveBackgroundColor);
            }
        }
    })

    return projectsColor;
}


export default getProjectStatusColor