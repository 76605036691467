import { put, takeEvery, call } from 'redux-saga/effects';
import { accountRequest, accountSuccess, accountFail } from '../slices/account';

import { accountApi } from '../api/account';



function* accountRequestSaga({ payload:{user_id} }) {
	try {
		const response = yield call(accountApi.show, user_id);
		yield put(accountSuccess(response.data.entities.user.item));
	}
	catch (error) {
		yield put(accountFail(error));
	}
}


export default function* accountSaga() {
	yield takeEvery(accountRequest, accountRequestSaga);
}