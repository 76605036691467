import { combineReducers } from 'redux';

import {topicsSlice, studentSlice, onuObjectivesSlice, workingSectorsSlice, notificationsSlice, authenticationSlice, accountSlice, coursesSlice, badgesSlice} from './slices';



const rootReducer = combineReducers({
	topicsSlice,
	studentSlice,
	onuObjectivesSlice,
	workingSectorsSlice,
	notificationsSlice,
	authenticationSlice,
	accountSlice,
	coursesSlice,
	badgesSlice,
});

export default rootReducer;
