import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isBrowser } from 'react-device-detect';
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';

import {
	fetchOnuObjectivesRequest,
	fetchWorkingSectorsRequest,
	notificationsFetchRequest,
	studentFetchRequest
} from '../../store/slices/';

import config from '../../config';

import {Col, Container, Row, Navbar, NavbarText, Nav } from 'reactstrap';
import {CardStudent, Loader} from '../../components';
import GuideCardList from './GuideCardList';
import MobileToggleIcon from '../../components/SidebarMenu/MobileToggleIcon';
import NotificationsBell from '../../components/NotificationsBell';

import Footer from '../../components/Footer';

import './style.css';



const StudentHome = ({ userSession }) => {

	const dispatch = useDispatch();

	const firstHomeAccess = Cookies.get('firstHomeAccess') || false; // used to show the first time video message

	useEffect(() => {
		dispatch(studentFetchRequest({user_id:userSession.id}));
		dispatch(fetchOnuObjectivesRequest());
		dispatch(fetchWorkingSectorsRequest());
		userSession.plan_slug !== "m1-o26" && dispatch(notificationsFetchRequest({user_id:userSession.id}));
	}, [dispatch, userSession]);

	const { data:student_info, status:studentStatus } = useSelector(state => state.studentSlice);
	const { data:all_ws, status:wsStatus } = useSelector(state => state.workingSectorsSlice);
	const { data:all_ob, status:obStatus } = useSelector(state => state.onuObjectivesSlice);
	const { data:notifications, all_notifications_as_read } = useSelector(state => state.notificationsSlice);

	// don't move from here!
	if ( studentStatus!=="completed" || wsStatus!=="completed" || obStatus!=="completed" ) {
		return <Loader />;
	}

	const modalWorkingSectors = all_ws.map(ws => student_info.user?.working_sectors.find(stud_ws => ws.id === stud_ws.id ) ? {...ws,...{is_selected:true}} : ws);
	const modalOnuObjectives = all_ob.map(ob => student_info.user.onu_objectives.find(stud_ob => ob.id === stud_ob.id ) ? {...ob,...{is_selected:true}} : ob);

	// Update user's properties in customerly crm
	if (process.env.NODE_ENV === 'production') {
		// eslint-disable-next-line no-undef
		const customerlyConfig = customerly.update({
			'user_id': userSession.id,
			'name': userSession.firstname,
			'email': userSession.email,
		});
		// eslint-disable-next-line no-undef
		const homepageEvent = customerly.event("homepage_loaded");
	}

	return (
		<>
			<Helmet>
				<title>{config.menuItems(userSession.plan_slug)[0].title}</title>
				<link rel="canonical" href={`https://www.start2impact.it/${config.basepath}`} />
				<script>{`
						(function() {
							if (typeof customerly === "function") {
								customerlyConfig;
								homepageEvent;
							}
						})()
					`}
				</script>
			</Helmet>

			<div className='home-page m-4 height-full-screen'>
				
				{/* Page Header */}
				<Container className="page-header">
					<Row>
						<Col xs={12} className="px-0 w-100 mb-3">
							<Navbar className="p-0" >
								<Nav className='d-flex'>
									<NavbarText className="page-title mt-auto">Ciao {student_info.user.firstname}</NavbarText>
								</Nav>
								<Nav>
									{
										!isBrowser && (
											<MobileToggleIcon />
										)
									}
									{
										(userSession.plan_slug !== 'm1-o26') &&
											<NotificationsBell notifications={notifications} user_id={student_info.user.id} notificationsAreRead={all_notifications_as_read} />
									}
								</Nav>
							</Navbar>
						</Col>
					</Row>
				</Container>
				
				{/* Page Body */}
				<Container>
					{ 
						//display only when the plan is "orientamento"	
						!(userSession.plan_slug==="m1-o26") && ( 
							<div className="w-100 pb-40">
								<CardStudent
									student={student_info}
									workingSectors={modalWorkingSectors}
									onuObjectives={modalOnuObjectives}
									userId={userSession.id}
								/>
							</div>
						)
					}
					<div className="w-100">
						<GuideCardList
							containers={student_info.path.containers}
							topic={student_info.path.topic}
							openFirstCardGuide={firstHomeAccess}
						/>
					</div>
				</Container>

				<Footer />

			</div>
		</>
	);
};

export default StudentHome;