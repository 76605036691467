import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { coursesFetchRequest } from '../../store/slices';

import { isBrowser } from 'react-device-detect';
import { Helmet } from "react-helmet";

import {  Col, Row, Container } from 'reactstrap';

import MobileToggleIcon from "../../components/SidebarMenu/MobileToggleIcon";
import CardCourseStatic from '../../components/CardCourse/CardCourseStatic';
import Footer from "../../components/Footer";
import { Loader } from '../../components';

import "./style.css";

import config from '../../config';


const Courses = ({ userSession }) => {

    const [courses, setCourses] = useState([]);
    const [searchedCourse, setSearchedCourse] = useState("");
    const [selectedTopicId, setSelectedTopicId] = useState(null);

	const dispatch = useDispatch();

	const { data: coursesData, status:coursesStatus } = useSelector(state => state.coursesSlice);
    const { data: topics} = useSelector(state => state.topicsSlice);

    useEffect(() => {
		dispatch(coursesFetchRequest({user_id:userSession.id}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const debouncedChangeCoursesSearch = useCallback( (ev) => {
        const timer = setTimeout( () => {
            ev.preventDefault();
            setSearchedCourse(ev.target.value)
        }, 350)
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        /* 
            default condition: selecTopicId is null && searchedCourse is an empty string;
            if both conditions are true then show all courses;
        */
        if (selectedTopicId === null && searchedCourse === "") {
            setCourses(coursesData);
        }  
        /* 
            when user select one topic set searchedCourse to empty string 
            otherwise if user search course by a string set selectTopicId to null
        */
        if (selectedTopicId !== null) {
            // if user selects a topic, show courses related to that topic
            setCourses(coursesData.filter(course => course.topic.id === selectedTopicId));
        }  
        
        if (searchedCourse.length > 0) {
            // if user searches a course then search that course into all courses, with searched string into course's name
            setCourses(
                coursesData
                    .filter(
                        course => course.title.toLowerCase().includes(searchedCourse.toLowerCase())
                    )
            )
        }
    },[selectedTopicId, searchedCourse, coursesData]);


    if (coursesStatus !== "completed") return <Loader />;

    return (
        <>
            <Helmet>
                <title>{config.menuItems(userSession.plan_slug)[2].title}</title>
            </Helmet>

            <div className='course-page p-1 p-lg-4'>

                {
                    !isBrowser && (<MobileToggleIcon />)
                }

                <Container>
                    <Row className='pb-3'>
                        <Col xs={12}>
                            <div className='tag-nav'>
                                <span key={'all_topic'} className={`tag-item ${!selectedTopicId && 'active'}`}>
                                    <a href="#tutti-gli-argomenti" onClick={() => setSelectedTopicId(null)}>Tutti</a>
                                </span>
                                {
                                    topics.length > 0 && topics.map( topic => {
                                        return (
                                            <span key={'topic_'+ topic.id} className={`tag-item ${selectedTopicId === topic.id && 'active'}`}>
                                                <a href={`#argomento-${topic.name?.toLowerCase().split(' ').join('-')}`} onClick={() => {
                                                    if(searchedCourse.length > 0){
                                                        setSearchedCourse("") 
                                                    }
                                                    setSelectedTopicId(topic.id)
                                                }}>
                                                    {topic.name}
                                                </a>
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>


                    <div>
                        <label htmlFor='searchCourse' className='input-label'>Nome corso</label>
                        <input id='searchCourse'
                            onChange={debouncedChangeCoursesSearch}
                            placeholder='Cerca il nome del corso'
                        />
                    </div>

                    <div className='courses-container'>
                        {
                            courses.length>0 && courses.map(course => {
                                return (
                                    <div key={course.id} className='course-card-body'>
                                        <CardCourseStatic
                                            isFinalProject={false}
                                            pic_url = {course?.image?.url || 'https://via.placeholder.com/150'}
                                            guide={course} 
                                        />
                                    </div>
                                )
                            })
                        }       
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    )
}


export default Courses;