export const calculateDaysFromToday = dateFrom => {
    const date = new Date(dateFrom).getTime();
    const now = new Date().getTime();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.round(diffTime / (1000 * 3600 * 24));
    return diffDays;
};


export const calculateDaysToToday = dateTo => {
    const date = new Date(dateTo).getTime();
    const now = new Date().getTime();
    const diffTime = date-now;
    const diffDays = Math.round(diffTime / (1000 * 3600 * 24));
    return diffDays;
};

export const isAfter = (date, dateToCompare) => {
    const _date = new Date(date).getTime();
    const _dateToCompare = new Date(dateToCompare).getTime();
    const diffTime = _date-_dateToCompare;
    const diffDays = Math.round(diffTime / (1000 * 3600 * 24));
    return diffDays<0;
}


export const isSameDay = (date1, date2) => {
    // console.log('isSameDay: ', new Date(date1).getDate() === new Date(date2).getDate() && new Date(date1).getMonth() === new Date(date2).getMonth() && new Date(date1).getFullYear() === new Date(date2).getFullYear());
    // console.log(date1);
    // console.log(date2);
    return new Date(date1).getDate() === new Date(date2).getDate() && new Date(date1).getMonth() === new Date(date2).getMonth() && new Date(date1).getFullYear() === new Date(date2).getFullYear()
};