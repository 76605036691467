import React, { useState, useEffect, useRef } from "react";
import { Player, BigPlayButton } from 'video-react'; // https://video-react.js.org/

import '../../../node_modules/video-react/dist/video-react.css';



const VideoPlayer = ({onVideoStart=()=>{}, onVideoEnd=()=>{}, ...props}) => {

    const videoRef = useRef(null);
    const [videoState, setVideoState] = useState(null);
    const [hasStarted, setHasStarted] = useState(false);
    const [hasEnded, setHasEnded] = useState(false);

    // Copying the player's state to a local component state on every change
    useEffect(() => {
        videoRef.current.subscribeToStateChange(setVideoState);
    }, [setVideoState]);

    // Checking if the video has started on every state update
    useEffect(() => {
        if (videoState && videoState.hasStarted && !hasStarted) {
            // Preventing onVideoEnd from being called multiple times
            setHasStarted(true);
            onVideoStart();
        }
    }, [videoState, hasStarted, setHasStarted, onVideoStart]);

    // Checking if the video has ended on every state update
    useEffect(() => {
        if (videoState && videoState.ended && !hasEnded) {
            // Preventing onVideoEnd from being called multiple times
            setHasEnded(true);
            onVideoEnd();
        }
    }, [videoState, hasEnded, setHasEnded, onVideoEnd]);

    return <Player ref={videoRef} {...props}>
        <BigPlayButton position="center" />
    </Player>
};

export default VideoPlayer;