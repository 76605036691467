import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { updateStudentWorkingSectorsListRequest } from '../../store/slices/';

import { Modal, ModalHeader, ModalBody,	ModalFooter, Row, Col, CardTitle, CardBody, CardImg, CardText, Card } from 'reactstrap';
import { FaRegEdit } from 'react-icons/fa';

import './style.css';


const countSelected = listObjectives => listObjectives.filter(obj => obj.is_selected===true).length;



const WorkingSectorModal = ({ sectorsList=[], userId, className }) => {

	const [modal, setModal] = useState(false);
	const [newListWorkingSectors, setnewListWorkingSectors] = useState(sectorsList);
	const [selectedWSCounter, setSelectedWSCounter] = useState(countSelected(newListWorkingSectors));

	const dispatch = useDispatch();
	const updateWorkingSectors = (sectorsList=[]) => {
		dispatch(updateStudentWorkingSectorsListRequest({user_id:userId, sectorsList}));
		setModal(!modal)
	}

	const refreshWSList = item => {
		const newList = toggleArrayItem(newListWorkingSectors, item);
		setnewListWorkingSectors(newList);
		setSelectedWSCounter(countSelected(newListWorkingSectors));
		//console.log(selectedOnuObjectivesCounter)
	}

	const toggleArrayItem = (arr, item) => {
		const itemIdx = arr.findIndex(el => el.id === item.id);
		typeof arr[itemIdx].is_selected === 'undefined'
			? arr[itemIdx] = ({...arr[itemIdx], is_selected:true})
			: arr[itemIdx].is_selected = !arr[itemIdx].is_selected;
			//console.log(arr[itemIdx].is_selected);
		return arr;
	}

	return (
		<>
			<button className='btn border-0 card-edit-link' onClick={()=>setModal(!modal)}>
				<FaRegEdit className='icon' />
			</button>
			<Modal isOpen={modal} toggle={()=>setModal(!modal)} className='working-sector-modal' size='lg'>
				<ModalHeader toggle={()=>setModal(!modal)}>
					Scegli i settori di tuo interesse su cui fare i progetti pratici.
				</ModalHeader>
				<ModalBody className={'pb-0'}>
					<Row>
						<Col xs={12}>
							Tramite Progetti basati su settori di tuo interesse scoprirai come le tue competenze, oltre ad essere molto richieste dalle aziende, generano un impatto positivo sulla società e sul pianeta. Seleziona uno o più settori.
						</Col>
					</Row>
					<Row className="mt-4">
						{
							newListWorkingSectors.map( item => {
								//console.log(item);
								return (
									<Col key={'col_sector_'+item.id} xs={12} lg={6} className='working-block-column'>
										<Card key={item.id}
											className={`working-block card-horizontal ${item.is_selected===true && 'selected-working-sector'} border-2`}
											onClick={()=>refreshWSList(item)}
											aria-hidden="true"
										>
											<CardBody className='p-0'>
												<div className='align-items-center working-block-body'>
													<div xs={4} className='px-0 text-center'>
														<CardImg src={'https://res.cloudinary.com/start2impact/image/upload/v1667204106/Piattaforma/working_sectors/'+item.filename} alt={'Icona settore lavorativo '+item.title} className='card-img-left flex-shrink-0 mb-3 mb-sm-0' />
													</div>
													<div xs={8}>
														<div className='d-block'>
															<CardTitle className='sector-title w-100 text-truncate mb-1'>
																{item.title}
															</CardTitle>
															<CardText className='text-secondary'>
																{item.description || "Descrizione non presente sul db"}
															</CardText>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</Col>
								)}
							)
						}
					</Row>
				</ModalBody>
				<ModalFooter className={'border-0'}>
					<button href="#save-working-sector" className='btn-modal mx-auto' onClick={()=>updateWorkingSectors(newListWorkingSectors)}>
						SALVA MODIFICHE
					</button>
				</ModalFooter>
			</Modal>
		</>
	);
};

WorkingSectorModal.propTypes = {
	sectorsList: PropTypes.arrayOf(PropTypes.object),
	className: PropTypes.string
}

export default WorkingSectorModal;
