import http from '../../helpers/httpService';
import config from '../../config';



const studentMaster = userId => `${config.apiUrl}/students/${userId}`;

export const studentApi = {
  show: userId => http.get(studentMaster(userId)),

  associateMaster: (userId, masterId) => http.post(`${studentMaster(userId)}/masters`, masterId),

  updateListOnuObjectives: (userId, list) => http.put(`${studentMaster(userId)}/onuobjectives`, list),
  updateListWorkingSectors: (userId, list) => http.put(`${studentMaster(userId)}/workingsectors`, list),

}