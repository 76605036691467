import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { updateStudentOnuObjectivesListRequest } from '../../store/slices/';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { FaRegEdit } from 'react-icons/fa';

import './style.css';

const countSelected = listObjectives => listObjectives.filter(obj => obj.is_selected===true).length;


const OnuObjectivesModal = ({ onuObjectivesList=[], userId, className }) => {

	const dispatch = useDispatch();

	const [modal, setModal] = useState(false);

	const [newListOnuObjectives, setnewListOnuObjectives] = useState(onuObjectivesList);
	const [selectedOnuObjectivesCounter, setSelectedOnuObjectivesCounter] = useState(countSelected(newListOnuObjectives));

	const updateOnuObjectives = (objectivesList=[]) => {
		dispatch(updateStudentOnuObjectivesListRequest({user_id:userId, objectivesList}));
		setModal(!modal)
	}

	const refreshListOnuObjectives = item => {
		if(item.is_selected || (!item.is_selected && selectedOnuObjectivesCounter < 3) ) {
			setnewListOnuObjectives(toggleArrayItem(newListOnuObjectives, item));
			setSelectedOnuObjectivesCounter(countSelected(newListOnuObjectives));
			//console.log('selectedOnuObjectivesCounter', selectedOnuObjectivesCounter);
		}
		return false;
	}

	const toggleArrayItem = (arr, item) => {
		const itemIdx = arr.findIndex(el => el.id === item.id);
		typeof arr[itemIdx].is_selected === 'undefined'
			? arr[itemIdx] = ({...arr[itemIdx], is_selected:true})
			: arr[itemIdx].is_selected = !arr[itemIdx].is_selected;
		return arr;
	}

	return (
		<div>
			<button className='btn border-0 card-edit-link' onClick={()=>setModal(!modal)}>
				<FaRegEdit className='icon' />
			</button>
			<Modal isOpen={modal} toggle={()=>setModal(!modal)} className='scope-change-modal' size='lg'>
				<ModalHeader toggle={()=>setModal(!modal)}>
					A quali grandi sfide della nostra epoca vorresti contribuire?
				</ModalHeader>
				<ModalBody className="">
					<Row>
						<Col xs={12}>
							Scegli fino a 3 Obiettivi di Sviluppo Sostenibile delle Nazioni Unite. Essere consapevoli delle cause che abbiamo a cuore ci può aiutare a scegliere aziende in cui sentirci realizzati con il nostro lavoro.
						</Col>
						{
							selectedOnuObjectivesCounter >= 3 && (
								<Col xs={12} className={'mt-3'}>
									<div className="alert alert-warning" role="alert">
										Hai raggiunto il numero massimo di 3 obiettivi selezionabili. Per cambiare scelta, deseleziona un obiettivo.
									</div>
								</Col>
							)
						}
					</Row>
					<div className='scope-block-list'>
						{
							newListOnuObjectives.map( item => (
								<div key={item.id}
									className={`scope-block ${item.is_selected===true && 'selected-onu-objective'}`}
									onClick={()=>refreshListOnuObjectives(item)}
									aria-hidden="true"
								>
									<div className='scope-block-bod d-flex flex-column'>
										<img className={`scopeBlockImage`} alt={item.title} src={'https://res.cloudinary.com/start2impact/image/fetch/'+item.pic_url} />
									</div>
								</div>
							))
						}
					</div>
				</ModalBody>
				<ModalFooter className={'border-0'}>
					<button className='btn-modal mx-auto' onClick={()=>updateOnuObjectives(newListOnuObjectives)}>
						SALVA MODIFICHE
					</button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

OnuObjectivesModal.propTypes = {
	onuObjectivesList: PropTypes.arrayOf(PropTypes.object),
	className: PropTypes.string,
}

export default OnuObjectivesModal;
