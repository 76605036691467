import React from 'react';

import { Card, CardBody, Row, Col, Progress } from 'reactstrap';
import PropTypes from 'prop-types'
import { FaRegEdit } from 'react-icons/fa';

import EditableCard from './EditableCard';
import WorkingSectorModal from '../ModalWorkingSector';
import OnuObjectivesModal from '../ModalOnuObjectives';

import config from './../../config.js';



const CardStudent = ({ student={}, workingSectors=[], onuObjectives=[], userId }) => {
	let hasSelectedWorkingSector = student.user.working_sectors.length > 0;
	let hasSelectedOnuObjectives = student.user.onu_objectives.length > 0;
	const studentProgress = student.progress>0 ? student.progress : 0;

	return (
		<Row>
			<Col xs={12} className='px-0'>
				<Card className='home-card'>
					<CardBody className='p-32'>
						<Row className={'pm-0 pt-0'}>
							<Col xs={12} lg={8} className="px-0 mt-sm-4">
								<div className='pr-0'>
									<EditableCard
										className="pl-0"
										title='Il Master che hai scelto &egrave;:'
										button={
											<a className='card-edit-link' href={`${config.basepath}master`}>
												<span className='d-none d-md-inline'>Cambia</span> <FaRegEdit className='icon' />
											</a>
										}
										body={<div className='list-item'>{student.path.title}</div>}
									/>
								</div>
							</Col>
							<Col xs={12} lg={4} className="px-0 mt-sm-4">
								<div className='pl-0'>
									<EditableCard
										className="pr-0"
										title='Percorso completo al:'
										body={
											<>
												<Progress value={studentProgress} className='w-100 me-4' />
												{studentProgress}%
											</>
										}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={8} className="px-0 mt-sm-4">
								<div className='pr-0'>
									<EditableCard
								     	className='pb-0 pl-0'
										title='I miei Settori di interesse:'
										button={
											<WorkingSectorModal className='card-edit-link' sectorsList={workingSectors} userId={userId} />
										}
										body={
											<div className='w-100'>
												<div className={`list-item image-list ${!hasSelectedWorkingSector && 'empty-image' }`}>
													{
														hasSelectedWorkingSector
														?
															student.user.working_sectors.map(sector => (
																	<div key={'sector_'+sector.id} className={`icon-block`}>
																		<img src={'https://res.cloudinary.com/start2impact/image/upload/v1667204106/Piattaforma/working_sectors/'+sector.filename} alt={'Icona settore lavorativo '+sector.title} className='icon img-fluid' height="80" width="80" />
																	</div>
																)
															)
														:
															[...Array(6)].map( (item,i) => (
																<div key={'sector_'+i} className={`icon-block square m-2`}></div>
															))
													}
												</div>
											</div>
										}
									/>
								</div>
							</Col>
							<Col xs={12} lg={4} className="px-0 mt-4">
								<div className='pl-0'>
									<EditableCard
								     	className='pb-0 pr-0'
										title='I miei Obiettivi ONU:'
										button={<OnuObjectivesModal className='card-edit-link' onuObjectivesList={onuObjectives} userId={userId} />}
										body={
											<div className='w-100'>
												<div className={`list-item image-list ${!hasSelectedOnuObjectives && 'empty-image' }`}>
													{
														hasSelectedOnuObjectives
														?
															student.user.onu_objectives.map(objective => {
																return (
																	<div  key={'onu_objective_'+objective.id} className={`icon-block m-2`}>
																		<img src={'https://res.cloudinary.com/start2impact/image/fetch/'+objective.pic_url} alt={objective.title} className='icon img-fluid' />
																	</div>
																)
															})
														:
															[...Array(3)].map( (item,i) => (
																<div key={'onu_objective_'+i} className={`icon-block square m-2`}></div>
															))
													}
												</div>
											</div>
										}
									/>
								</div>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);

};

CardStudent.propTypes = {
    workingSectors: PropTypes.arrayOf(PropTypes.object),
	onuObjectives: PropTypes.arrayOf(PropTypes.object),
}

export default CardStudent;
