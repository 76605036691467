import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import { topicsFetchRequest } from '../../store/slices';

import { Col, Container, Row } from "reactstrap";
import Logo from "../../components/Logo";
import Loader from "../../components/Loader";
import Footer  from "../../components/Footer";
import CardMaster from "./CardMaster";
import ModalMasterDetails from "../../components/ModalMasterDetails";

import { RxCheckCircled } from "react-icons/rx";

import styles from "./style.module.css";
import config from "../../config";



const getAllMasters = topics => topics.flatMap( topic => topic.paths);


const Upselling = ({userSession}) => {

    const dispatch = useDispatch();

    const [masters, setMasters] = useState([]);
    const [selectedMaster, setSelectedMaster] = useState(null);
    const [selectedTopicId, setSelectedTopicId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: topics , status: topicsStatus} = useSelector(state => state.topicsSlice);

    const openModalMasterDetails = master => {
        setSelectedMaster(master);
        setIsModalOpen(true);
    };

    const filterMasterByTopic = (topic_id=null) => {
		const mastersByTopic = topic_id !== null ? topics.find(topic => topic.id === topic_id).paths : getAllMasters(topics);
		setMasters(mastersByTopic);
		setSelectedTopicId(topic_id);
	}

    useEffect(() => {
        dispatch(topicsFetchRequest({user_id:userSession.id}))
    }, [dispatch, userSession]);

    useEffect( () => {
		setMasters(getAllMasters(topics));
	}, [topics]);

    if (topicsStatus !== "completed") return <Loader />;

    return (
        <>
            <Helmet>
                {/* TODO add to config file */}
                <title>Inizia il tuo Master</title>
            </Helmet>

            <div className={`${styles.pageContainer}`}>
                <header className={`px-4 ${styles.header}`}>
                    <Container className="p-0">
                        <Logo basepath={config.basepath} isCollapsed={false} className={styles.logo} type="white" />

                        <div className={styles.headerContentContainer}>
                            <h3 className={styles.headerTitle}>Master in</h3>
                            <h2 className={styles.headerSubtitle}>
                                Digital Marketing, Full Stack, UX/UI Design, <br/>Data Science e Blockchain
                            </h2>

                            <div className={styles.pricingTextContainer}>
                                <div className="d-flex justify-content-center">
                                    <p className={styles.barredPrice}>897€</p>
                                    <h3 className={styles.pricingText}> 747€ per 1 anno </h3>
                                </div>
                                <h6 className={styles.headerSecondaryText}>poi 299€/anno senza vincoli di rinnovo</h6>
                            </div>

                            <Row className={styles.servicesList}>
                                <Col md={"6"}>
                                    <div className={styles.servicesListField}>
                                        <RxCheckCircled size={24} />
                                        <p>Non serve laurea o esperienza</p>
                                    </div>
                                    <div className={styles.servicesListField}>
                                        <RxCheckCircled size={24} />
                                        <p>Progetti individuali su casi reali</p>
                                    </div>
                                    <div className={styles.servicesListField}>
                                        <RxCheckCircled size={24} />
                                        <p>Correzioni da professionisti</p>
                                    </div>
                                    <div className={styles.servicesListField}>
                                        <RxCheckCircled size={24} />
                                        <p>Online senza vincoli di orari</p>
                                    </div>
                                
                                </Col>

                                <Col md={"6"}>
                                    <div className={styles.servicesListField}>
                                        <RxCheckCircled size={24} />
                                        <p>Supporto finché non trovi il lavoro adatto a te</p>
                                    </div>
                                    <div className={styles.servicesListField}>
                                        <RxCheckCircled size={24} />
                                        <p>Stage retribuito garantito</p>
                                    </div>
                                    <div className={styles.servicesListField}>
                                        <RxCheckCircled size={24} />
                                        <p>Gruppi studio online e Meetup dal vivo</p>
                                    </div>
                                    <div className={styles.servicesListField}>
                                        <RxCheckCircled size={24} />
                                        <p>Puoi cambiare Master, senza costi aggiuntivi</p>
                                    </div>
                                </Col>
                            </Row>

                            <h6 className={styles.headerSecondaryText}>Potrai pagare anche in 3 rate da 249€ senza interessi con Scalapay</h6>

                        </div>
                    </Container>
                </header>

                <main className={styles.main}>
                    <Container>
                        <h2 className={styles.mainTitle}> Scegli il tuo master </h2>
                        <div className={styles.topicSelector}>
                            <div className='tag-nav'>
                                <span key={'all_topic'} className={`tag-item ${!selectedTopicId && 'active'}`}>
                                    <a href="#tutti-gli-argomenti" onClick={() => filterMasterByTopic(null)}>Tutti</a>
                                </span>
                                {
                                    topics.length > 0 && topics.map( topic => {
                                        return (
                                            <span key={'topic_'+ topic.id} className={`tag-item ${selectedTopicId === topic.id && 'active'}`}>
                                                <a href={`#argomento-${topic.name?.toLowerCase().split(' ').join('-')}`} onClick={() => filterMasterByTopic(topic.id)}>
                                                    {topic.name}
                                                </a>
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className={styles.mastersContainer}>
                            <Row className="gy-4">
                                {masters.length>0 && masters.map(master => {
                                        return (
                                            <Col key={`master${master.id}`} lg={4} md={6}>
                                                <CardMaster openModalMasterDetails={openModalMasterDetails} master={master} />
                                            </Col>
                                        )
                                })}
                            </Row>
                        </div>
                    </Container>
                    {
                        isModalOpen && (
                            <ModalMasterDetails
                                toggle={() => setIsModalOpen(false)}
                                master={selectedMaster}
                                startNewMaster={() => window.location.href =`/checkout?plan=m1-o27`}
                            />
                        )
				    }
                </main>
            </div>
            <Footer />
        </>
    )
}


export default Upselling;

