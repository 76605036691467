import http from '../../helpers/httpService';
import config from '../../config';



const notificationsPath = userId => `${config.notificationsApiUrl}/users/${userId}/notifications`;

export const notificationsApi = {
  list: userId => http.get(notificationsPath(userId)+'/?limit=25'),
  //setBellOff: userId => http.post(`${notificationsPath(userId)}/set_read_notification_at`),
  setAllAsRead: userId => http.post(`${notificationsPath(userId)}/mark_all_notifications_as_read`),
  setOneAsRead: (userId, notificationId) => http.post(`${notificationsPath(userId)}/${notificationId}/mark_notification_as_read`),
}