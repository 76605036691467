import React from 'react';

import { CircleProgress } from 'react-gradient-progress';

import { FaBusinessTime } from 'react-icons/fa';
import { Card, Badge, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect';

import './style.css';



const CardPath = ({ topic, id, subinfo, title, student_infos, description, guides_count, projects_count, job_success_rate, job_success_time, toggleModal, startNewPath=null }) => {
	//console.log(id);
	return (
		<>
			<style dangerouslySetInnerHTML={{
				__html: ['.path-card-'+id+':before {background-color: '+topic.color+'}'].join('')
			}}>
			</style>
			<Card className={'path-card path-card-'+id+' shadow mb-3 ps-5 pe-4'}>
				<Row>
					<Col xs={12} lg={10}>
						<Row>
							{
								subinfo && (
									<Col xs={12} className='mt-3'>
										<Badge className='path-badge my-2' color='#fff'>{subinfo}</Badge>
									</Col>
								)
							}
							<Col xs={12} className={`mb-md-0 ${subinfo ? 'mt-2' : 'mt-4'}`}>
								<Row>
									<Col xs={12} md={3}>
										<span className='path-title'>{title}</span>
									</Col>
									<Col xs={12} md={4} className='my-3 my-md-0'>
										<div className='projects-count'>
											<strong>{guides_count}</strong>&nbsp;<span className='percorsi-body-button'>guide</span>
										</div>
										<div className='projects-count'>
											<strong>{projects_count}</strong>&nbsp;<span className='percorsi-body-button'>progetti</span>
										</div>
									</Col>
									<Col xs={12} md={5}>
										<p className='percorsi-body-text' dangerouslySetInnerHTML={{__html: description}} />
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col xs={12} lg={2} className={`mt-md-2 ${subinfo ? 'mt-md-4' : 'mt-2'}`}>
						<Row className="text-center">
							<Col xs={6} md={12}>
								<span className='percorsi-progress-header'>Completamento<br />Percorso:</span>
							</Col>
							<Col xs={6} md={12} className='mt-md-1 percorsi-progress-header'>
								<CircleProgress
									percentage={student_infos?.progress || 0}
									width={75}
									strokeWidth={7}
									primaryColor={['#007369', '#08F7A1']}
									secondaryColor={'#E6F1F0'}
									fontColor={'#222'}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col xs={12} md={6} lg={4}>
						{
							job_success_rate && (
								<div className='offer-message d-flex align-items-center mb-md-0 mb-3'>
									<FaBusinessTime className='icon' />
									<span className='all-text'>
										<strong>II {job_success_rate}%</strong> di chi finisce questo Master trova lavoro entro {job_success_time} mesi
									</span>
								</div>
							)
						}
					</Col>
					<Col xs={12} md={{ size:6, offset:2 }} className='mb-3 d-flex flex-row-reverse'>
						<button key='1' className={`btn d-inline-block px-3 ${isMobile ? 'btn-custom-light':'btn-custom-light'}`} onClick={()=>toggleModal()}>
							Scopri di pi&ugrave;
						</button>
						<button key='2' className={`btn d-inline-block me-3 px-3 ${isMobile ? 'btn-custom-dark':'btn-custom-dark'}`} onClick={()=>startNewPath(id)}>
							{student_infos?.is_current ? 'Riprendi' : 'Inizia'}
						</button>
					</Col>
				</Row>
			</Card>
		</>
	);
};


CardPath.propTypes = {
    topic: PropTypes.object,
	subinfo: PropTypes.string,
	title: PropTypes.string,  
	student_info: PropTypes.objectOf(PropTypes.any),
	description: PropTypes.string, 
	guides_count: PropTypes.number, 
	projects_count: PropTypes.number, 
	job_success_rate: PropTypes.number, 
	job_success_time: PropTypes.number,
	toggleModal: PropTypes.func,
	startNewPath: PropTypes.func
}

export default CardPath;
