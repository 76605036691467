
import React, {useState} from 'react';
import { useDispatch } from 'react-redux';

import { setAllNotificationsAsReadRequest, setNotificationAsReadRequest } from '../../store/slices/notifications';

import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { BiBell } from 'react-icons/bi';
import { IoList } from 'react-icons/io5';
import { FaFileInvoice } from 'react-icons/fa';

import './style.css';



const NotificationBell = ({notifications, user_id, notificationsAreRead}) => {

    const dispatch = useDispatch();

    const [dropdownOpen, setDropdownOpen] = useState(false);
   
    const hasNotifications = notifications.length > 0;
    const hasUnreadNotification = hasNotifications ? notifications.filter( notification => notification['read_at']===null ) : false;

    const setAllNotificationsAsRead = () => {
		dispatch(setAllNotificationsAsReadRequest({user_id}));
	}

    const handleClickNotification = notification => {
        //e.preventDefault();
        //console.log(`The link of the notification  ${JSON.stringify(notification)} was clicked.`);
        dispatch(setNotificationAsReadRequest({user_id, notification, notifications}));
        if(notification.url) window.location.href = notification.url;
    };

    const toggle = () => setDropdownOpen(prevState => !prevState);


    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} className="notification ms-3">
            <DropdownToggle className="notification-toggle">
                <BiBell className="notification-toggle-icon" />
                { !notificationsAreRead && hasUnreadNotification.length>0 && (<Badge className="notification-badge" color="#5EC868">{hasUnreadNotification.length}</Badge>) }
            </DropdownToggle>
            <DropdownMenu className="notification-list" end>
                <DropdownItem className="notification-header" header>
                    <span className="notification-header-title">Notifiche {hasUnreadNotification.length>0 && '('+ hasUnreadNotification.length +')' }</span>
                    {!notificationsAreRead && hasUnreadNotification.length>0 && <a onClick={()=>setAllNotificationsAsRead()} href="#set_notifications_as_read" className="mark-all-read">Segna tutte come gi&agrave; lette <IoList className="mark-all-read-icon" /></a>}
                </DropdownItem>
                <div className="notification-list-wrap">
                    {
                        hasNotifications && notifications.map( (notification, index) => (
                            <DropdownItem className={`notification-item ${ (notification.read_at===null && !notificationsAreRead) && 'unread' }`} key={index} onClick={()=>handleClickNotification(notification)}>
                                <span className="d-flex align-items-center notification-link"
                                    // href={notification.url} target='_blank' rel='noreferrer'
                                    // onClick={()=> handleClickNotification(notification)}
                                >
                                    <div className="flex-shrink-0 notification-img">
                                        <FaFileInvoice className="notification-icon" />
                                    </div>
                                    <div className="flex-grow-1 notification-info">
                                        <h6 className="notification-title">{notification.header}</h6>
                                        <span className="notification-text">{notification.body_text}</span>
                                    </div>
                                </span>
                            </DropdownItem>
                        ))
                    }
                </div>
            </DropdownMenu>
        </Dropdown>);
}

export default NotificationBell;