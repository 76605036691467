/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from "react-router-dom";
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import analytics from '../helpers/analytics';

import { accountRequest } from '../store/slices/account';
import { hadAccessRequest, isSubscriptionOkRequest } from '../store/slices/authentication';

import config from '../config.js';



export const checkCookie = cookieName => {
  try {
    const cookie = Cookies.get(config.cookieName);
    return cookie ? jwt_decode(cookie) : false;
  }
  catch (err) {
    Cookies.remove(config.cookieName);
    return false;
  }
}

const AuthContext = createContext();


export function AuthProvider ({children}) {

    const [session, setSession] = useState(null);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    
    const location = useLocation();
    const dispatch = useDispatch();

    const { data:user, status:userStatus, error:userError } = useSelector(state => state.accountSlice);
    const { lastAccess, isSubscriptionOk } = useSelector(state => state.authenticationSlice);
    const subdomain = window.location.hostname.split('.')[0];

    useEffect( () => {
      const session = checkCookie(config.cookieName);
      if (session) {
        setSession(session)
      }
      else {
        return window.location.href = config.loginPath;
      }
    }, []);

    useEffect( () => {
      if (
        (userStatus==="completed" && user?.id===null) // user request is completed but there isn't user.id
        || userError!==null // there aren't errors on user request
        || (subdomain==="talent"&&session?.plan_slug==="m1-o18") // verifies that user cannot change his subdomain
      ) {
          window.location.href = config.loginPath;
      }
      setIsLoading(false);
    }, [session, user]);

    useEffect( () => {
      if(isSubscriptionOk?.redirect) {
        //return (<Redirect to={isSubscriptionOk.redirect_to} />);
        return window.location.href = isSubscriptionOk.redirect_to;
      }
    }, [isSubscriptionOk, dispatch]);

    useEffect( () => {
      if (error) setError(null);
      if (session?.user) {
        dispatch(accountRequest({user_id:session.user.id}));
        dispatch(isSubscriptionOkRequest({user_id:session.user.id}));
        dispatch(hadAccessRequest({user_id:session.user.id}));
      }
    }, [session, location.pathname]); // if I don't track session, at first load, user will be null

    useEffect( () => {
      if (userStatus==="completed") {
        if( user.id !== null && process.env.NODE_ENV==='production') {
          analytics.initialize(user.id, user.firstname+' '+user.lastname);
          analytics.pageview(window.location.pathname + window.location.search);
        }
      }
    }, [user, location.pathname])

    // Make the provider update only when it should
    const memoedValue = useMemo( () => ({
        session, user
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [session, isLoading, error, user]
    );
  
    /* set AuthContext */
    return (
      <AuthContext.Provider value={memoedValue}>
        {!isLoading && children}
      </AuthContext.Provider>
    );

}
  

export default function useAuth() {
    return useContext(AuthContext);
}