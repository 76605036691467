import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: {
		id: null,
		email: null,
		slug: null,
		bio: null,
		firstname: null,
		lastname: null,
		pic_profile: null,
		points: 0,
		consecutive_sign_in_count: 0,
	},
	error: null,
	status: 'idle' // ['idle', 'loading', 'completed']
}

const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		accountRequest(state, action) {
			state.status = 'loading';
		},
		accountSuccess(state, action) {
			state.data.id = action.payload.id;
			state.data.email = action.payload.email;
			state.data.slug = action.payload.slug;
            state.data.firstname = action.payload.firstname;
			state.data.lastname = action.payload.lastname;
			state.data.bio = action.payload.bio;
			state.data.pic_profile = action.payload.pic_profile;
            state.data.points = action.payload.points;
            state.data.consecutive_sign_in_count = action.payload.consecutive_sign_in_count;
			state.status = 'completed';
			state.error = null;
		},
		accountFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		}
	},
});


export const {
    accountRequest, accountSuccess, accountFail,
} = accountSlice.actions;

export default accountSlice.reducer;
