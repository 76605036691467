import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
	data: {
		id: null,
		is_current: false,
		master: {},
		progress: 0,
		user: {},
		created_at: null
	},
	error: null,
	status: 'idle' // ['idle', 'loading', 'completed', 'error'] 
};


const studentSlice = createSlice({
	name: 'student',
	initialState,
	reducers: {
		studentFetchRequest(state, action) {
			state.status = 'loading';
		},
		studentFetchSuccess(state, action) {
			state.data = action.payload;
			state.status = 'completed';
		},
		studentFetchFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		},
		setStudentMasterRequest(state, action) {
			state.status = 'loading';
		},
		// setStudentPathSuccess(state, action) {
		// 	//state.data = action.payload;
		// 	console.log('Master changed to: ' + action.payload);
		// },
		setStudentMasterFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		},
		updateStudentWorkingSectorsSuccess(state, action) {
			state.error = null;
			state.data.user.working_sectors = action.payload;
		},
		updateStudentWorkingSectorsFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		},
		updateStudentOnuObjectivesSuccess(state, action) {
			state.error = null;
			state.data.user.onu_objectives = action.payload;
		},
		updateStudentOnuObjectivesFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		}
	},
});


export const updateStudentWorkingSectorsListRequest = createAction('update_student_working_sectors_request');
export const updateStudentOnuObjectivesListRequest = createAction('update_student_onu_objectives_request');

export const {
	studentFetchRequest, studentFetchSuccess, studentFetchFail,
	setStudentMasterRequest, setStudentMasterFail,
	updateStudentOnuObjectivesSuccess, updateStudentOnuObjectivesFail,
	updateStudentWorkingSectorsSuccess, updateStudentWorkingSectorsFail
} = studentSlice.actions;

export default studentSlice.reducer;
