import React from "react";
import {Card, CardBody, CardTitle} from "reactstrap";

import Cookies from 'js-cookie';

import styles from "./style.module.css";
import config from "../../../config";


const setCookieForSelectedMaster = masterId => {
    Cookies.set('selected_master', masterId);
}


const CardMaster = ({openModalMasterDetails, master }) => {

    const { id, title, subinfo, guides_count, projects_count, description, topic } =  master;

    return (
        <Card className={`h-100 ${styles.card}`}>

            {
                subinfo && <div className={styles.subInfo}>
                    <span style={{color: topic?.color || "black"}} dangerouslySetInnerHTML={{__html: subinfo}} />
                </div>
            }

            <div className={styles.cardImage}>
                <img alt={`Copertina del master ${title}`} src={`${config.basepath}images/masters/${id}.webp`} width="350" height="180" />
            </div>
            
            <CardBody className={styles.cardBody}>
                <CardTitle className={styles.cardTitle} style={{color: topic?.color || "black"}} tag="h4">{title}</CardTitle>
                <div className="mb-3 d-flex" tag="h6">
                    <div className="d-flex align-items-baseline me-3">
                        <h6 className={styles.counterText}>{guides_count}</h6>
                        <p>Corsi</p>
                    </div>
                    <div className="d-flex align-items-baseline">
                        <h6 className={styles.counterText}>{projects_count}</h6>
                        <p>Progetti</p>
                    </div>
                </div>

                <span className={styles.description} dangerouslySetInnerHTML={{__html: description}} />

                <div className={styles.cardFooter}>
                    <hr className={styles.hr}/>
                    <div className="d-flex justify-content-end">
                        <button className={`btn-custom-light d-inline-block me-3 ${styles.button}`}
                            onClick={() => {
                                setCookieForSelectedMaster(master.id)
                                openModalMasterDetails(master)
                            }}
                        >
                            Scopri di più
                        </button>
                        <a className={`btn-custom-dark d-inline-block ${styles.button}`}
                            href={`/checkout?plan=m1-o27`}
                            onClick={()=>setCookieForSelectedMaster(master.id)}
                        >
                            Inizia
                        </a>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default CardMaster;