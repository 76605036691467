import { BiUserPin, BiBookBookmark } from 'react-icons/bi';
import { AiOutlineUser, AiOutlineHome, AiOutlineTrophy } from 'react-icons/ai';
import { MdWorkOutline } from 'react-icons/md';
import { HiOutlineAcademicCap } from 'react-icons/hi';


const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    notificationsApiUrl: process.env.REACT_APP_NOTIFICATION_URL,
    authenticationApiUrl: process.env.REACT_APP_AUTHENTICATION_URL,
    cookieName     : 'auth_token',
    loginPath      : 'login',
    basepath       : '/',
    onboardingPath : '/onboarding/master',
    daysShowWelcomeVideoAfterRegistration: 7,
    googleAnalyticsTrackerId: process.env.NODE_ENV === 'production' ? 'UA-94118148-1' : null,
    menuItems: (plan="m1-o18") => plan==="m1-o26" // plan===piano orientamento
    ? [
        {
            id: 1,
            name: 'Home',
            title: 'Home | start2impact University',
            to: '/home',
            active:true,
            isInternal:true,
            icon: AiOutlineHome
        },
        {
            id: 2,
            name: 'Career Counseling',
            title: 'Corso Career Counseling | start2impact University',
            to: '/corsi/career-counseling',
            active:false,
            isInternal:false,
            icon: MdWorkOutline
        },
        { id: 3, name: 'Account', to: '/subscribe/index', active:false, isInternal:false, icon: AiOutlineUser }
    ]
    : [
        {
            id: 1,
            name: 'Home',
            title: 'Home | start2impact University',
            to: '/home',
            active:true,
            isInternal:true,
            icon: AiOutlineHome
        },
        {
            id: 2,
            name: 'Master',
            title: 'Scegli il tuo Master | start2impact University',
            to: '/master',
            active:false,
            isInternal:true,
            icon: HiOutlineAcademicCap
        },
        {
            id: 3,
            name: 'Corsi',
            title: 'Tutti i Corsi | start2impact University',
            to: '/corsi',
            active: false,
            isInternal: true,
            icon: BiBookBookmark
        },
        { id: 4, name: 'Classifica',     to: '/rank/index',      active:false, isInternal:false, icon: AiOutlineTrophy },
        {
            id: 5,
            name: 'Community',
            title: 'Community | start2impact University',
            to: '/community',
            active: false,
            isInternal: true,
            icon: BiUserPin
        },
        { id: 6, name: 'Career Service', to: '/topics/mindset/guides/career-service', active:false, isInternal:false, icon: MdWorkOutline },
        { id: 7, name: 'Account',        to: '/subscribe/index', active:false, isInternal:false, icon: AiOutlineUser }
    ]
}


export default config;