/* eslint-disable react/style-prop-object */
import React from 'react';
import { FaBook, FaTasks, FaFileInvoice } from 'react-icons/fa'
import { Doughnut } from 'react-chartjs-2';

import { createGradient } from '../../../helpers/colors';

import getProjectStatusColor from "../getProjectStatusColor";
import progressColors from '../progressColors';
import styles from "./style.module.css"



const doughnutSetting = {
    maintainAspectRatio: true,
    responsive: true,
	cutout: '70%',
	plugins: {
		legend: {display:false, position:'right'},
		tooltip: {enabled: false},
	},
	elements: {
		arc: {borderWidth: 1},
		rotation: 0,
		circumference: 360/1,
	},
};

const GuideCircularProgress = ({
    theory_progress=0,
    test_points=0, test_retries=0, test_blocked_until=null,
    project_blocked_until=null, project_retries=0, project_resend=false, project_sentnotcorrected=null,
    projects_count=0, projects_completed=0, projects_points=0,
    isFinalProject
}) => {

    const projectsDataset = {
        data: projects_count>1 ? [...Array(projects_count)].map( (x,i) => 100/projects_count ) : [100],
        backgroundColor: context => getProjectStatusColor(context, projects_completed, project_sentnotcorrected, project_resend, project_blocked_until, project_retries, projects_count),
        icon : [...Array(projects_count)].map( (x,i) => projects_completed>=i+1?styles.iconGreen: (project_sentnotcorrected!==null)?styles.iconYellow: (project_resend===true)?styles.iconOrange : (project_blocked_until!==null || project_retries>0)?styles.iconRed:  styles.iconGray)
    };

    return (
        <div className='d-flex'>
            {
                !isFinalProject && (
                    <div className={styles.chartContainer}>
                        <Doughnut
                            data={
                                {
                                    labels: ['Teoria'],
                                    datasets: [
                                        {
                                            data: theory_progress>0?[theory_progress, 100-theory_progress]:[100],
                                            backgroundColor: (context) => theory_progress>0?[createGradient(context),progressColors.inactiveBackgroundColor]:[progressColors.inactiveBackgroundColor],
                                            hoverBorderColor: ['transparent'],
                                        },
                                    ]
                                }
                            }
                            options={doughnutSetting}

                            width={56}
                        />
                        {
                            <div>
                                <FaBook className={`${styles.chartIcon} ${theory_progress===100? styles.iconGreen : styles.iconGray}`} />
                            </div>
                        }
                    </div>
                )
            }
            {
                !isFinalProject && (
                    <div className={styles.chartContainer}>
                        <Doughnut
                            data={
                                {
                                    maintainAspectRatio: true,
                                    responsive: true,
                                    datasets: [
                                        {
                                            data: [100],
                                            backgroundColor: (context) => test_points>0?[createGradient(context)]: (test_retries>0||test_blocked_until!==null)?[progressColors.blockedBackgroundColor]:[progressColors.inactiveBackgroundColor],
                                            hoverBorderColor: ['transparent'],
                                        },
                                    ]
                                }
                            }
                            options={doughnutSetting}
                            width={56}
                        />
                        {
                            <div>
                                <FaTasks className={`${styles.chartIcon} ${test_points>0? styles.iconGreen : (test_retries>0||test_blocked_until!==null)? styles.iconRed : styles.iconGray}`} />
                            </div>
                        }
                    </div>
                )
            }
            {
                projects_count>0 && (
                    <div className={styles.chartContainer}>
                        <Doughnut
                            data={
                                {
                                    maintainAspectRatio: true,
                                    responsive: true,
                                    datasets: [
                                        {
                                            data: projectsDataset.data,
                                            backgroundColor: projectsDataset.backgroundColor,
                                            hoverBorderColor: ['transparent'],
                                        },
                                    ]
                                }
                            }
                            options={doughnutSetting}
                            width={56}
                        />
                        {
                            <div>
                                <FaFileInvoice className={`${styles.chartIcon} ${projectsDataset.icon[projectsDataset.icon.length-1]}`} />
                            </div>
                        }
                    </div>
                )
            }
        </div>
    );
};


export default GuideCircularProgress;