import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchWorkingSectorsRequest, fetchWorkingSectorsSuccess, fetchWorkingSectorsFail } from '../slices/workingSectors';

import { workingsectorsApi } from '../api/working_sectors';
//import { working_sectors } from '../fakedb';



function* fetchWorkingSectorsSaga() {
	try {
		//yield put(fetchWorkingSectorsSuccess(working_sectors));
		const response = yield call(workingsectorsApi.list);
		yield put(fetchWorkingSectorsSuccess(response.data.entities.working_sectors.items.data));
	}
	catch (error) {
		yield put(fetchWorkingSectorsFail('There was an error trying to get the request.'));
	}
}

export default function* workingSectorsSaga() {
	yield takeEvery(fetchWorkingSectorsRequest.toString(), fetchWorkingSectorsSaga);
}