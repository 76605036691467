import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: [],
	error: null,
	status: 'idle'
};



const onuObjectivesSlice = createSlice({
	name: 'onu_objectives',
	initialState,
	reducers: {
		fetchOnuObjectivesRequest(state, action) {
			state.status = 'loading';
		},
		fetchOnuObjectivesSuccess(state, action) {
			state.data = action.payload;
			state.status = 'completed';
		},
		fetchOnuObjectivesFail(state, action) {
			state.error = action.payload;
			state.status = 'error';
		}
	},
});


export const { fetchOnuObjectivesRequest, fetchOnuObjectivesSuccess, fetchOnuObjectivesFail } = onuObjectivesSlice.actions;
export default onuObjectivesSlice.reducer;