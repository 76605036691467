import { put, takeEvery, call } from 'redux-saga/effects';
import { coursesFetchFail, coursesFetchRequest, coursesFetchSuccess, } from '../slices/courses';

import { coursesApi } from '../api/courses';
import { topicsFetchSuccess } from '../slices';

import {coursesEntities} from "../fakedb/courses";



function* fetchCoursesSaga({ payload:{user_id} }) {
	try {
		const response = yield call(coursesApi.show, user_id);
		yield put(topicsFetchSuccess(response.data.entities.topics.items.data));
		yield put(coursesFetchSuccess(response.data.entities.courses.items.data));

		// yield put(topicsFetchSuccess(coursesEntities.topics));
		// yield put(coursesFetchSuccess(coursesEntities.courses));
	} catch (error) {
		yield put(coursesFetchFail(error));
	}
}

export default function* pathSaga() {
	yield takeEvery(coursesFetchRequest.toString(), fetchCoursesSaga);
}
