import React from 'react';
import { BrowserRouter, Switch } from "react-router-dom";

import { AuthProvider } from "../context/authContext";

import LayoutMenu from '../layouts/LayoutMenu.js';
import LayoutWithoutMenu from '../layouts/LayoutWithoutMenu.js';

import ModalWelcomeVideo from '../components/ModalWelcomeVideo';
import PortalBadge from "../portals/badges/PortalBadge";

import StudentHome from '../features/StudentHome';
import Masters from '../features/Masters';
import Community from '../features/Community';
import Error404 from '../features/404';
import Courses from '../features/Courses';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import './style.css';


import config from '../config.js';


export default function Talent() {
	// console.log("loaded talent");
	return (
		<BrowserRouter>
			<AuthProvider>
				<ModalWelcomeVideo />
				<PortalBadge />
				<Switch>
					<LayoutMenu exact path={`${config.basepath}home`} component={StudentHome} />
					<LayoutWithoutMenu exact path={config.onboardingPath} component={Masters} />
					<LayoutMenu exact path={`${config.basepath}master`} component={Masters} />
					<LayoutMenu exact path={`${config.basepath}corsi`} component={Courses} />
					<LayoutMenu exact path={`${config.basepath}community`} component={Community} />
					<LayoutWithoutMenu path='*' component={Error404} />
				</Switch>
			</AuthProvider>
		</BrowserRouter>
	)
}