import React from "react";

import { Helmet } from "react-helmet";
import { Container, Nav } from "reactstrap";

import Footer from "../../components/Footer";
import MobileToggleIcon from "../../components/SidebarMenu/MobileToggleIcon";

import classes from "./404.module.css";

const Error404 = () => {
    return (
        <>
            <Helmet>
                <title>Pagina non trovata | start2impact University</title>
            </Helmet>

            <Container
                fluid
                className={`${classes.page404} min-vh-100 d-flex flex-column justify-content-center align-items-center text-center`}
            >
                <Nav className={classes.navbar}>
                    <MobileToggleIcon />
                </Nav>

                <img src="/images/404.svg" alt="404" />
                <h1 className="w-0 h-0 visually-hidden">404 - Pagina non disponibile</h1>

                <span>Ops! Pagina non disponibile</span>
                <p>Scrivi in chat cosa stavi cercando e ti aiuteremo.</p>

                <a href="/home">Torna alla home</a>
            </Container>
            <Footer />
        </>
    );
};

export default Error404;
