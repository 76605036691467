import http from '../../helpers/httpService';
import config from '../../config';



const authenticationPath = userId => `${config.authenticationApiUrl}/users/${userId}`;

export const authenticationApi = {
  hadAccess: userId => http.post(`${authenticationPath(userId)}/had_access`),
  isSubscriptionOk: userId => http.get(`${authenticationPath(userId)}/ensure_payment`),
}