import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchOnuObjectivesRequest, fetchOnuObjectivesSuccess, fetchOnuObjectivesFail } from '../slices/onuObjectives';

import { onuobjectivesApi } from '../api/onu_objectives';
//import { onu_objectives } from '../fakedb';



function* fetchOnuObjectivesSaga() {
	try {
		const response = yield call(onuobjectivesApi.list);
		yield put(fetchOnuObjectivesSuccess(response.data.entities.onu_objectives.items.data));
	}
	catch (error) {
		yield put(fetchOnuObjectivesFail('There was an error trying to get the request.'));
	}
}

export default function* onueObjectivesSaga() {
	yield takeEvery(fetchOnuObjectivesRequest.toString(), fetchOnuObjectivesSaga);
}